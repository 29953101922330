/* Browsers: last 4 version */

/* Start Timeline Carousel Portion CSS Code Here */
.timeline_carousel h2 {
  color: #313131;
  font-family: "silomregular";
  /* margin-top: 50px; */
  margin-top: 40px;
  margin-bottom: 75px;
  text-align: center;
}
.timeline_carousel {
  margin-top: 50px;
}
.timeline_carousel .timeline_status ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  list-style: none;
  position: relative;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  padding-top: 25px;
}

.timeline_carousel .timeline_status ul::before {
  content: "";
  width: 97%;
  height: 1px;
  background-color: #313131;
  display: block;
  position: absolute;
  top: 0;
  left: 17px;
}
.timeline_carousel .timeline_status ul li {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.timeline_carousel .timeline_status ul li span.inner_span {
  height: 10px;
  width: 10px;
  border-radius: 100%;
  background-color: #707070;
  display: block;
  position: absolute;
  top: -30px;
  left: 14.5px;
  z-index: 1;
}

.timeline_carousel .timeline-item.active span.inner_span {
  background-color: #313131;
}
.timeline_carousel .timeline-item.active .circle_img {
  background-image: url("../TimelineCarousel/border.png");
  position: absolute;
  height: 26px;
  width: 26px;
  top: -38px;
  background-repeat: no-repeat;
  background-size: 100%;
  left: 6.5px;
}
.timeline_carousel .timeline-item {
  position: relative;
}

.timeline_carousel .active a {
  font-family: "mulishbold" !important;
}
.timeline_carousel .timeline_status a {
  color: #313131;
  text-decoration: none;
  font-family: "mulishmedium";
  font-size: 14px;
  margin-left: 3px;
}
.timeline_carousel .owl-carousel .owl-item.active.center .item img {
  -webkit-transform: scale(1.2) !important;
  -ms-transform: scale(1.2) !important;
  transform: scale(1.2) !important;
}
.timeline_carousel .owl-carousel {
  padding-top: 50px;
  padding-bottom: 0;
}
.timeline_carousel .owl-carousel .owl-stage-outer {
  overflow: hidden;
}
.timeline_carousel .owl-carousel .owl-item .item {
  opacity: 0.5;
  padding: 25px;
  -webkit-transform: scale3d(0.8, 0.8, 1);
  transform: scale3d(0.8, 0.8, 1);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.timeline_carousel .owl-carousel .owl-item.active.center .item {
  /* transform: scale(1.5) !important; */
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.timeline_carousel .owl-carousel .owl-item.active.center .item p {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  text-align: center;
}
.timeline_carousel .owl-carousel .owl-item .item p {
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin-top: 35px;
  color: #313131;
  font-family: "mulishregular";
}
.timeline_carousel .owl-carousel .owl-nav.disabled {
  display: block;
}

.timeline_carousel .owl-nav {
  position: absolute !important;
  top: 34%;
  width: 100%;
  z-index: 23;
}

.timeline_carousel .owl-prev {
  left: 0;
  position: absolute;
  font-size: 40px !important;
  outline: none !important;
  background-color: transparent !important;
}

.timeline_carousel .owl-next {
  right: 0;
  position: absolute;
  font-size: 40px !important;
  outline: none !important;
  background-color: transparent !important;
}
.timeline_carousel .owl-nav .owl-next::after,
.timeline_carousel .owl-nav .owl-prev::after {
  content: "";
  height: 40px;
  width: 40px;
  background-color: #f5f5f5;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 16px;
  left: -14px;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}
.timeline_carousel .owl-nav .owl-next:hover:after,
.timeline_carousel .owl-nav .owl-prev:hover:after {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  opacity: 1;
}
.timeline_carousel .owl-nav {
  position: relative;
  z-index: 2;
}
.timeline_carousel .owl-prev:hover span {
  color: #313131;
  background-color: transparent !important;
}
.timeline_carousel .owl-next:hover span {
  color: #313131;
  background-color: transparent !important;
}
/* End Timeline Carousel Portion CSS Code Here */

/* Start Timeline Carousel Portion Responsive Media Query Code Here */

@media only screen and (min-width: 1500px) {
  .timeline_carousel .timeline_status a {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1199px) {
  .timeline_carousel .timeline_status ul::before {
    width: 96% !important;
  }
}
@media only screen and (max-width: 991px) {
  .timeline_carousel .timeline_status ul::before {
    width: 95% !important;
  }
  .timeline_carousel .timeline_status a {
    font-size: 13px;
    margin-left: 0px;
  }
  .timeline_carousel .timeline_status ul li span.inner_span {
    left: 11.5px;
  }
  .timeline_carousel .timeline-item.active .circle_img {
    left: 3.5px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .timeline_carousel h2 {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .timeline_carousel .timeline_status ul::before {
    width: 94% !important;
  }
  .timeline_carousel {
    margin-top: 30px;
  }
  .timeline_carousel h2 {
    margin-bottom: 55px;
  }
  .timeline_carousel .owl-carousel .owl-nav {
    display: none !important;
  }
}
@media only screen and (max-width: 575px) {
  .timeline_carousel .timeline_status ul::before {
    width: 93% !important;
  }
}
@media only screen and (max-width: 424px) {
  .timeline_carousel .timeline_status ul::before {
    width: 90% !important;
  }
}

/* End Timeline Carousel Portion Responsive Media Query Code Here */
