/* Browsers: last 4 version*/

/* Start Case Studies Detail Portion CSS Code Here */

.case_studies_top {
  margin-top: 120px;
  margin-bottom: 50px;
}

.case_studies_top .case_top p {
  color: #313131;
  font-family: "mulishlight";
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1px;
}

.case_studies_top img {
  width: 100%;
}

.case_studies_top .case_top .badge {
  background-color: #2c2c2e !important;
  color: white;
  border-radius: 0;
  padding: 8px 15px;
  font-family: "mulishregular";
  font-weight: normal !important;
  position: relative;
}

.case_studies_top .case_top .badge:after {
  content: "";
  width: 1px;
  height: 15px;
  background-color: #707070;
  position: absolute;
  left: 0;
  bottom: -28px;
}

.case_studies_top .case_top .badge span {
  margin-right: 35px;
  display: inline-block;
  position: relative;
}

.case_studies_top .case_top .badge span:not(:last-child)::after {
  content: "";
  width: 1px;
  height: 13px;
  background-color: white;
  position: absolute;
  top: 0px;
  right: -20px;
}

.case_studies_top .case_top .badge span:last-child {
  margin-right: 0;
}

.case_studies_top .case_top h5 {
  color: #313131;
  font-family: "silomregular";
  margin: 40px 0px;
}

.case_studies_top .case_top h6 {
  color: #2c2c2e;
  font-family: "mulishsemibold";
  /* max-width: 400px;*/
  width: 100%;
}

.case_studies_top .case_top {
  position: relative;
  height: 100%;
}

.case_studies_top .case_top:after {
  content: "";
  width: 1px;
  height: 130px;
  /* height: 196px; */
  background-color: #707070;
  position: absolute;
  bottom: 0;
}

.case_studies_top .top_mt {
  /* margin-top: 70px; */
  margin-top: 90px;
}

.case_studies_top .top_mt h6 {
  color: #2c2c2e;
  font-family: "mulishsemibold";
  text-align: left;
  margin-bottom: 70px;
}

.case_studies_top .top_mt h6 ol {
  -webkit-padding-start: 15px;
  padding-inline-start: 15px;
  margin-bottom: 30px;
}

.case_studies_top .top_mt ul {
  -webkit-padding-start: 19px;
  padding-inline-start: 19px;
}

.case_studies_top .top_mt h6 ol li,
.case_studies_top .top_mt h6 ul li {
  margin-bottom: 15px;
}

.case_studies_top .top_mt h6 span {
  display: inline-block;
}

.case_studies_top .top_mt h6:last-child {
  margin-bottom: 50px;
}

.case_studies_top .top_mt ul li {
  margin-bottom: 15px;
}

.case_studies_layout {
  /* margin-bottom: 70px; */
  margin-bottom: 90px;
}

.case_studies_layout .col-lg-4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.case_studies_layout .case_studies_box {
  border: 1px solid #707070;
  padding: 50px;
  max-width: 252px;
  margin: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  text-align: center;
  height: 100%;
  position: relative;
}

.case_studies_layout .col-lg-4::after {
  content: "";
  width: 1px;
  height: 43px;
  position: absolute;
  right: 0;
  background-color: #707070;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 76px;
}

.case_studies_layout .col-lg-4:last-child::after {
  display: none;
}

.case_studies_layout .case_studies_box svg {
  height: 30px;
  width: 30px;
}

.case_studies_layout .case_studies_box h6 {
  font-size: 14px;
  color: #2c2c2e;
  font-family: "mulishsemibold";
  margin-top: 20px;
}

.case_studies_banner .case_studies_text {
  background-color: #2c2c2e;
  padding: 70px;
}

.case_studies_banner .case_studies_text p {
  color: white;
  font-family: "mulishregular";
  margin: 0;
}

.case_studies_banner img {
  width: 100%;
}

.case_studies_banner {
  position: relative;
  padding-bottom: 200px;
}

.case_studies_banner::after {
  content: "";
  height: 100px;
  width: 1px;
  background-color: #2c2c2e;
  display: block;
  position: absolute;
  bottom: 60px;
  opacity: 0.5;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.similar_case_studies h2 {
  color: #313131;
  font-family: "silomregular";
  margin-top: 50px;
  margin-bottom: 50px;
}

.similar_case_studies {
  /* margin-bottom: 70px; */
  margin-bottom: 90px;
}

.similar_case_studies .card {
  border: none;
}

.similar_case_studies .card .card-img-top {
  border-radius: 0;
}

.similar_case_studies .card .similar_img {
  border: 1px solid #ffc014;
  position: relative;
  cursor: pointer;
}

.similar_case_studies .card .similar_img .card-title {
  position: absolute;
  bottom: 0px;
  /* color: #313131; */
  color: white;
  padding: 20px;
  font-family: "mulishsemibold";
  letter-spacing: 0.5px;
  /* font-family: 'silomregular'; */
  font-size: 13px;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  text-align: center;
}

.similar_case_studies .card .card-title {
  font-family: "mulishsemibold";
  font-size: 18px;
  margin-top: 15px;
}

.similar_case_studies .col-lg-4 {
  padding: 0;
}

.similar_case_studies .col-lg-4:first-child {
  padding-left: 0px;
}

.similar_case_studies .col-lg-4:last-child {
  padding-right: 0px;
}

/* End Case Studies Detail Portion CSS Code Here */
