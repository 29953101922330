/* Browsers: last 4 version*/

/* Start Case Studies Home Page Portion CSS Code Here */

.case_studies_home {
  background-color: #1a1a1c;
  padding-top: 140px;
}

.case_studies_home .case_tab ul {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
}

.case_studies_home .case_tab ul li {
  display: inline-block;
  margin: 0 30px;
  position: relative;
}

.case_studies_home .case_tab ul li:after {
  content: "";
  position: absolute;
  top: 6px;
  right: -30px;
  height: 15px;
  width: 1px;
  background-color: white;
}

.case_studies_home .case_tab ul li:last-child:after {
  display: none;
}

.case_studies_home .case_tab ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.case_studies_home .case_tab ul li a {
  color: white;
  font-family: "mulishregular";
  font-size: 14px;
  text-decoration: none;
}

.case_studies_home .case_tab ul li.active a {
  color: #4390e0;
}

.case_studies_home p {
  color: white;
  font-family: "mulishlight";
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1px;
}

.case_studies_home .input-group {
  max-width: 500px;
  margin: auto;
  margin-bottom: 50px;
  margin-top: 50px;
  width: 100%;
}

.case_studies_home .input-group .form-control {
  border-radius: 0;
  border-color: white;
}

.case_studies_home .input-group .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.case_studies_home .input-group .form-control::-webkit-input-placeholder {
  font-family: "mulishitalic";
  color: #313131;
  font-size: 14px;
  opacity: 0.8;
}

.case_studies_home .input-group .form-control::-moz-placeholder {
  font-family: "mulishitalic";
  color: #313131;
  font-size: 14px;
  opacity: 0.8;
}

.case_studies_home .input-group .form-control:-ms-input-placeholder {
  font-family: "mulishitalic";
  color: #313131;
  font-size: 14px;
  opacity: 0.8;
}

.case_studies_home .input-group .form-control::-ms-input-placeholder {
  font-family: "mulishitalic";
  color: #313131;
  font-size: 14px;
  opacity: 0.8;
}

.case_studies_home .input-group .form-control::placeholder {
  font-family: "mulishitalic";
  color: #313131;
  font-size: 14px;
  opacity: 0.8;
}

.case_studies_home .input-group .input-group-text {
  border-color: transparent !important;
  background-color: white;
  border-radius: 0;
}

.case_not_found {
  color: white;
  font-family: "mulishregular";
  margin-bottom: 50px;
}

.case_studies_home .input-group .input-group-text#close_icon {
  cursor: pointer;
}

.case_studies_home .case_overlay {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies_home .card:hover .case_overlay {
  /* border: 1px solid #707070; */
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies_home .case_overlay .c_overlay {
  background-color: #19508930;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies_home .case_overlay h5 {
  text-align: center;
  color: white;
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 20px;
  font-family: "mulishsemibold";
  letter-spacing: 0.5px;
  /* font-family: "silomregular"; */
  font-size: 13px;
  padding: 0 35px;
  padding-bottom: 3px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.case_studies_home .case_overlay h5 span {
  text-transform: uppercase;
}

.case_studies_home .card:hover .c_overlay {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies_home .card:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies_home .card img {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies_home .card .card-body .card_tag {
  font-family: "mulishregular";
  font-size: 13px;
  color: white;
  text-align: center;
  margin-bottom: 1.1rem;
}

.case_studies_home .card {
  background-color: transparent;
  /* cursor: pointer; */
}

.case_studies_home .card .card-body .card_tag span {
  position: relative;
  margin: 0 13px;
  font-size: 12px;
  display: inline-block;
}

.case_studies_home .card .card-body .card_tag span::after {
  content: "";
  position: absolute;
  right: -15px;
  height: 15px;
  width: 1px;
  background-color: white;
}

.case_studies_home .card .card-body .card_tag span:last-child:after {
  display: none;
}

.case_studies_home .card {
  border-color: transparent;
  margin-bottom: 50px;
  padding-left: 15px;
  padding-right: 15px;
}

.case_studies_home .case_tab {
  margin-bottom: 50px;
}

.case_studies_home .card {
  /* opacity: 0; */
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

.case_studies_home .card.fade_in {
  /* opacity: 1; */
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  /* transition: opacity .15s linear; */
}

/* End Case Studies Home Page Portion CSS Code Here */

.fade-in-top {
  -webkit-animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-top 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-10-2 10:49:10
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in-top
 * ----------------------------------------
 */

@-webkit-keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
