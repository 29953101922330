/* Browsers: last 4 version */

/* Start Footer Portion CSS Code Here */
footer {
  background-color: #2c2c2e;
  padding: 90px 0px;
}

footer .footer_link ul {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style: none;
  margin-bottom: 0;
}

/* footer .footer_link ul:nth-child(2){
  max-width: 223px;
  width: 100%;
} */
footer .footer_link ul h5 {
  color: white;
  opacity: 0.3;
  text-transform: uppercase;
  font-family: "mulishlight";
  font-size: 14px;
  letter-spacing: 0.5px;
  margin-bottom: 17px;
  margin-top: 6px;
}

footer .footer_link ul:first-child {
  /* margin-right: 105px; */
  max-width: 223px;
  width: 100%;
}

footer .footer_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .footer_link ul li {
  margin-bottom: 15px;
}

footer .scrolledToBottom.footer_detail span p,
footer .scrolledToBottom.footer_detail a {
  color: #ffc014 !important;
}

footer .footer_link ul li a {
  /* color: #ffffff; */
  color: #939393;
  text-decoration: none;
  font-family: "mulishlight";
  font-size: 14px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

footer .footer_link ul li.active a {
  color: #ffffff;
}

footer .footer_link ul li:hover a {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

footer .footer_detail {
  margin-top: 30px;
}

footer .footer_detail .footer_logo svg {
  width: 115px;
  cursor: pointer;
}

footer .footer_detail .footer_data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

footer .email_mobile a:hover {
  color: #ffc014 !important;
}

footer .footer_detail .footer_data .footer_logo {
  position: relative;
  margin-right: 40px;
  max-width: 120px;
}

footer .footer_detail .footer_data .footer_logo img {
  width: 100%;
}

footer .footer_detail .footer_data .footer_logo::after {
  content: " ";
  width: 21px;
  height: 1px;
  background-color: #ffffff;
  position: absolute;
  right: -46px;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  top: 14px;
}

footer .footer_detail .footer_data span p {
  font-family: "mulishlight";
  color: #ffffff;
  font-size: 14px;
  margin-left: 40px;
  width: 290px;
  line-height: 18px;
}

footer .footer_detail .email_mobile a {
  color: #ffffff;
  text-decoration: none;
  font-size: 14px;
  font-family: "mulishlight";
}

footer .footer_detail .email_mobile .dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #ffffff;
  margin: -15px 5px 0px 15px;
  display: inline-block;
}

footer .footer_media .newsletter_input {
  position: relative;
}

footer .footer_media .newsletter_input input {
  border: 0;
  border-bottom: 1px solid white;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
  padding-right: 84px;
  color: #ffc900;
}

footer .footer_media .newsletter_input input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

footer .footer_media .newsletter_input input::-webkit-input-placeholder {
  font-family: "mulishitalic";
}

footer .footer_media .newsletter_input input::-moz-placeholder {
  font-family: "mulishitalic";
}

footer .footer_media .newsletter_input input:-ms-input-placeholder {
  font-family: "mulishitalic";
}

footer .footer_media .newsletter_input input::-ms-input-placeholder {
  font-family: "mulishitalic";
}

footer .footer_media .newsletter_input input::placeholder {
  font-family: "mulishitalic";
}

footer .footer_media .newsletter_input .news_arrow {
  position: absolute;
  right: -8px;
  top: -7px;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: transparent;
  color: white;
}

footer .footer_media .newsletter_input .news_arrow svg {
  width: 40px;
  height: 39px;
}

footer .footer_media .newsletter_input .news_arrow:active,
footer .footer_media .newsletter_input .news_arrow:focus {
  background-color: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

footer .footer_media .form-label {
  color: white;
  margin-bottom: 22px;
  font-family: "mulishregular";
  font-size: 14px;
}

footer .footer_media .followus .form-label {
  margin-bottom: 30px;
  font-family: "mulishregular";
  font-size: 14px;
}

footer .footer_media {
  max-width: 340px;
  width: 100%;
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: space-evenly;
  -ms-flex-pack: space-evenly;
  justify-content: space-evenly;
  height: 100%;
}

footer .footer_media .followus ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style: none;
}

footer .footer_media .followus ul li {
  margin-right: 25px;
}

footer .footer_media .followus ul li a i {
  color: white;
  font-size: 22px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

footer .footer_media .followus ul li a svg {
  width: 30px;
  margin-top: -6px;
}

footer .footer_media .followus ul li:hover i,
footer .footer_media .followus ul li:hover svg {
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

footer .footer_media {
  position: relative;
}

footer .col-lg-6 .scroll_top {
  /* position: absolute; */
  /* right: 16px;
  top: 0; */
  cursor: pointer;
  height: 40px;
  width: 40px;
  position: fixed;
  right: 44px;
  bottom: 70px;
  -webkit-animation: bounce 1600ms infinite
    cubic-bezier(0.445, 0.05, 0.55, 0.95);
  animation: bounce 1600ms infinite cubic-bezier(0.445, 0.05, 0.55, 0.95);
  background-color: #ffc014;
  border-radius: 100%;
  z-index: -1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0;
}

footer .col-lg-6 .scroll_top img {
  width: 100%;
}

footer .col-lg-6 .scroll_top.show {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

@-webkit-keyframes bounce {
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

@keyframes bounce {
  50% {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

footer .copyright p {
  text-align: right;
  font-size: 14px;
  font-family: "mulishregular";
  color: white;
  margin: 0;
}

/* End Footer Portion CSS Code Here */
