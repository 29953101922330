/* Browsers: last 4 version */

/* Start N-Collector Portion CSS Code Here */
.ncollector {
  margin-top: 140px;
}
.ncollector img {
  width: 100%;
  padding-right: 120px;
}
.ncollector .heading_n {
  color: #313131;
  text-transform: uppercase;
  font-family: "mulishlight";
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 50px;
}
.ncollector .n_collector_top {
  padding-left: 80px;
}
.ncollector .n_collector_top p {
  color: #313131;
  font-family: "mulishregular";
}
.ncollector .n_collector_top p span {
  font-family: "mulishbold";
  display: inline-block;
}
.ncollector .n_collector_top h5 {
  color: #2c2c2e;
  margin-bottom: 20px;
  font-family: "mulishbold";
  font-size: 18px;
}
.ncollector .n_collector_top .n_collector__innertop {
  position: relative;
}
.ncollector .n_collector_top .n_collector__innertop::after {
  content: "";
  width: 1px;
  height: 30px;
  background-color: #707070;
  position: absolute;
  left: 0;
  bottom: -50px;
}
.ncollector .n_collector_top .position_btn {
  border: 1px solid #313131;
  max-width: 175px;
  margin-top: 35px;
}
.ncollector .n_collector_top .position_btn button {
  color: #2c2c2e;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
  -ms-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
  width: 173px;
  border: 1px solid #fdc02d;
  background-color: white !important;
  text-align: center;
}
.ncollector .n_collector_top .view_case {
  margin-top: 70px;
  margin-bottom: 0;
}
.ncollector .n_collector_top .position_btn button:hover,
.ncollector .n_collector_top .position_btn button:active {
  color: #313131;
}
.ncollector .n_collector_top .position_btn button:focus {
  /* background-color: #fdc02d !important; */
  border-color: #fdc02d !important;
}

.product_heading {
  font-family: "silomregular";
  margin: 50px 0px;
  margin-top: 90px;
  color: #313131;
}
.product_specification {
  margin-bottom: 5px;
  background-color: #f5f5f5;
  padding: 70px 0px;
}
.product_specification .card {
  border: 0;
  background-color: transparent;
  margin: auto;
  width: 18rem;
}
.product_specification .card h6 {
  color: #2c2c2e;
  font-family: "mulishsemibold";
  margin-bottom: 30px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.product_specification .card .card-text {
  font-family: "mulishregular";
  color: #313131;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
/* End N-Collector Portion CSS Code Here */

/* Start N-Collector Portion Responsive Media Query CSS Code Here  */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ncollector img {
    padding-right: 0;
  }
  .ncollector .viewmap {
    max-width: 475px;
    margin: auto;
  }
  .ncollector .n_collector_top {
    padding-left: 0;
  }
  .product_specification .card {
    width: auto;
  }
  .product_specification .card .card-body {
    padding: 0;
  }
  .product_specification {
    padding: 50px 0px;
  }
  .ncollector {
    margin-top: 120px;
  }
  .ncollector .viewmap {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .ncollector img {
    padding-right: 0;
  }
  .ncollector {
    margin-top: 120px;
  }
  .ncollector .viewmap {
    max-width: 475px;
    margin: auto;
  }
  .ncollector .n_collector_top {
    padding-left: 0;
  }
  .product_specification .card {
    width: auto;
    margin-bottom: 25px;
  }
  .product_specification .col-md-4:last-child .card {
    margin-bottom: 0;
  }
  .product_specification .card .card-body {
    padding: 0;
  }
  .product_specification {
    padding: 50px 0px;
  }
  .ncollector .viewmap {
    margin-bottom: 30px;
  }
  .product_heading {
    margin-top: 50px;
  }
}

/* End N-Collector Portion Responsive Media Query CSS Code Here  */
