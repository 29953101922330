/* Browsers: last 4 version */

/* Start Products Layers Portion CSS Code Here  */
.layers_top {
  background-color: #1a1a1c;
  padding-top: 140px;
}
.layers_top .heading_layers {
  color: white;
  font-family: "mulishlight";
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 50px;
  text-transform: uppercase;
}
.layers_top .layers_title p {
  color: white;
  font-family: "mulishregular";
  font-size: 15px;
}
.layers_top .layers_title p span {
  font-weight: 600;
  letter-spacing: 1px;
}

.n_collector {
  padding: 70px 0;
}
.n_collector .card h5 {
  color: #2c2c2e;
  margin-bottom: 20px;
  font-family: "mulishsemibold";
  font-size: 16px;
}
.n_collector .card span {
  font-family: "mulishbold";
}
.n_collector .card p {
  font-family: "mulishregular";
  margin-bottom: 30px;
}
.n_collector .card .position_btn {
  border: 1px solid #313131;
  max-width: 175px;
}
.n_collector .card .position_btn button {
  color: #2c2c2e;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
  -ms-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
  width: 173px;
  text-align: left;
  border: 1px solid #fdc02d;
  background-color: white !important;
  text-align: center;
}
.n_collector .card .position_btn button:hover {
  background-color: #fdc02d !important;
  border-color: #fdc02d !important;
}
.n_collector .card .position_btn button:focus {
  color: #1a1a1c;
}
/* End Products Layers Portion CSS Code Here  */
