/* Browsers: last 4 version */

/* Start Insights Tab Content portion Responsive Media Query CSS Code here  */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .insights_tab .nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 50px;
  }
  .insights_tab{
    margin-bottom: 50px;
  }

}
@media only screen and (max-width: 767px){
    .insights_tab .nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin-top: 50px;
      }
      .insights_tab{
        margin-bottom: 50px;
      }
      .insights_tab .nav-pills .nav-link{
        max-width: 95px;
        font-size: 13px;
      }
      .insights_tab .nav-pills .nav-link::before{
        width: 13px;
        left:8px;
        top:12px
      }
      .insights_tab .col-11{
        margin:auto;
        margin-bottom: 30px;
      }
}
/* End Insights Tab Content portion Responsive Media Query CSS Code here  */

