/* Browsers: last 4 version */

/* Start Social Content Portion CSS Code Here */
.social_content img {
  width: 100%;
}
.social_content .col-lg-6 {
  padding: 0;
}
.social_content .social_content_box {
  position: relative;
  cursor: pointer;
}
.social_content .social_content_box .social_overlay {
  position: absolute;
  background-color: #2c2c2e70;
  height: 100%;
  width: 100%;
  color: white;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.social_content .social_content_box:hover .social_overlay {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.social_content .social_content_box span .fas {
  margin-right: 10px;
}
.social_content .social_content_box span {
  max-width: 150px;
  background-color: #195089;
  color: white;
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 11px;
  border-radius: 40px;
  padding: 2px 18px;
  text-transform: uppercase;
  z-index: 1;
}
.social_content .social_content_box .logo {
  height: 40px;
  width: 40px;
}
.social_content .social_content_box .logo img {
  height: 100%;
  width: 100%;
}

.social_content .announcement_box span {
  background-color: #2c2c2e;
}
.social_content .social_content_box .social_text {
  position: absolute;
  bottom: 0px;
  background-color: #2c2c2e87;
  width: 90%;
  z-index: 1;
  -webkit-transform: translate(-50%, -30%);
      -ms-transform: translate(-50%, -30%);
          transform: translate(-50%, -30%);
  left: 50%;
  padding: 5px;
}
.social_content .social_content_box .social_text p {
  color: white;
  font-family: "mulishregular";
  font-size: 13px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  margin-bottom: 0px;
}
/* End Social Content Portion CSS Code Here */

/* Start Social Content Portion Responsive Media Query CSS Code Here  */
@media only screen and (max-width: 767px) {
  .social_content .social_content_box .logo {
    height: 30px;
    width: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .social_content .col-lg-6 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/* End Social Content Portion Responsive Media Query CSS Code Here  */