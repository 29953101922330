/* Browsers: last 4 version */

/* Start Footer Portion Code Responsive Media Query CSS  */
@media only screen and (max-width: 991px) {
  footer .footer_media {
    max-width: 100%;
    margin-top: 50px;
    height: auto;
  }
  footer .copyright p {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 0;
  }
  footer .footer_media .followus .form-label {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  footer .footer_media .form-label {
    margin-bottom: 11px;
  }
  footer .followus {
    text-align: center;
  }
  footer .footer_media .followus ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer_detail .footer_data {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  footer .footer_detail .footer_data .footer_logo {
    margin-right: 0;
  }
  footer .footer_detail .footer_data .footer_logo::after {
    display: none;
  }
  footer .footer_detail .footer_data span p {
    margin-left: 0;
    margin-top: 30px;
  }
  footer .email_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    /* align-items: center; */
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: start;
  }
  footer .footer_detail .email_mobile .dot {
    margin: 5px 0px;
  }
  /* footer  .followus{
    text-align: center;
  }
  footer .footer_media .followus ul{
    justify-content: center;
  } */
  footer .footer_media .followus ul li:last-child {
    margin-right: 0;
  }
  footer .col-lg-6 .scroll_top {
    height: 30px;
    width: 30px;
    right: 20px;
    bottom: 20px;
  }
}

/* End Footer Portion Code Responsive Media Query CSS  */
