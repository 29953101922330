/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.swal-button.swal-button--confirm {
    background-color: #fdc02d;
}

.swal-button:not([disabled]):hover {
    background-color: #fdc02d;
}

.swal-icon--success__line {
    background-color: #fdc02d;
}

.swal-icon--success__ring {
    border: 4px solid hsla(215, 48%, 37%, 0.57);
}

.swal-icon--success {
    border-color: #1B59B3;
}