/* Browsers: last 4 version */

/* Start Papers Detail Portion CSS Code Here  */
.papers_detail {
  position: relative;
  margin-bottom: 100px;
}

.papers_detail button {
  color: #313131;
  font-family: "mulishlight";
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  float: right;
  margin-top: -62px;
}

.papers_detail .papers_card_box {
  position: relative;
  border: 1px solid #70707063;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.papers_detail img {
  width: 100%;
}

.papers_detail .papers_card_box .paper_detail_layer {
  border: 1px solid #707070;
  -webkit-transform: translate(-10px, -10px);
      -ms-transform: translate(-10px, -10px);
          transform: translate(-10px, -10px);
  background-color: white;
}

.papers_detail .papers_card_box .papers_descp {
  background-color: #313131;
}

.papers_detail .papers_card_box .paper_img {
  position: relative;
}

.papers_detail .papers_card_box .paper_text {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 30px 50px;
}

.papers_detail .papers_card_box .paper_text h6 {
  font-family: "silomregular";
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  font-size: 13px;
  color: white;
}

.papers_detail .papers_card_box .paper_text p {
  color: white;
  font-family: "mulishregular";
  font-size: 12px;
}

.papers_detail .papers_card_box .papers_descp {
  padding: 30px 50px;
  padding-top: 50px;
}

.papers_detail .papers_card_box .papers_descp h6 {
  font-family: "silomregular";
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  font-size: 13px;
  color: #e8e8e8;
  margin-bottom: 20px;
}

.papers_detail .papers_card_box .papers_descp p {
  color: #e8e8e8;
  font-family: "mulishregular";
  /* font-size: 12px; */
  font-size: 13px;
}

.papers_detail .paper_detl_card {
  position: relative;
  border: 1px solid #70707063;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.papers_detail .col-md-6 {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}

.papers_detail .paper_detl_card .papers_text h6 {
  color: #2c2c2e;
  font-family: "silomregular";
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  margin-bottom: 16px;
  font-size: 13px;
}

.papers_detail .paper_detl_card .papers_text {
  padding: 30px;
}

.papers_detail .paper_detl_card .papers_img img {
  width: 100%;
  /* transition: all 0.5s ease-in-out; */
}

.papers_detail .paper_detl_card .papers_overlay {
  border: 1px solid #707070;
  -webkit-transform: translate(-10px, -10px);
      -ms-transform: translate(-10px, -10px);
          transform: translate(-10px, -10px);
  background-color: white;
}

.detail_card_m {
  margin-top: 100px;
}

.papers_detail .rotate_heading {
  text-align: center;
  font-family: "mulishbold";
  color: #2c2c2e;
  letter-spacing: 4px;
  font-size: 14px;
  margin-bottom: 0;
  position: absolute;
  left: -248px;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  bottom: 232px;
}


.papers_detail .like_box {
  text-align: center;
  background-color: #313131;
  /* padding-bottom: 30px; */
  padding-bottom: 50px;
  margin-top: -1px;
}

.papers_detail .like_box button {
  float: none;
  margin: auto;
  color: white;
}

.papers_detail .like_box button:hover,
.papers_detail .like_box button:active,
.papers_detail .like_box button:focus {
  color: white;
}

.papers_detail .like_box button i {
  font-size: 20px;
  color: white;
}

.papers_detail .like_box button .fas {
  color: white;
}

.papers_detail .like_box small {
  font-family: "mulishregular";
  font-size: 12px;
  color: white;
}

.papers_detail .like_box .download_blog {
  margin-right: 20px;
}

.papers_detail .like_box .download_blog svg {
  height: 20px;
  width: 20px;
}

/* .papers_detail .like_box p {
  font-family: "mulishlight";
  font-size: 12px;
  color: white;
} */


.papers_detail .owl-carousel .item {
  padding: 20px;
}

.papers_detail .owl-carousel .owl-prev {
  left: -50px;
}

.papers_detail .owl-carousel .owl-next {
  right: -50px;
}

.papers_detail .owl-carousel .owl-nav {
  display: block;
  position: absolute;
  width: 100%;
  top: 40%;
}

.papers_detail .owl-dot {
  margin: 0;
  float: none;
}

@media only screen and (max-width: 767px) {
  .papers_detail .owl-carousel .owl-nav {
    display: none;
  }

  .papers_detail .owl-carousel .item {
    padding: 10px;
  }
}


/* End Papers Detail Portion CSS Code Here  */