/* Browsers: last 4 version */

/* Start Blog Detail Portion CSS Code Here */
.blog_detail {
  margin-top: 20px;
  position: relative;
}

.blog_detail .text-dark {
  color: #313131;
  font-family: "mulishlight";
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  float: right;
  /* margin-bottom: 30px; */
  margin-top: -62px;
}

.blog_detail .blog_detail_date span {
  color: #313131;
  font-family: "mulishregular";
  font-size: 13px;
}

.blog_detail .blog_detail_date .dot {
  height: 5px;
  width: 5px;
  display: inline-block;
  background-color: #313131;
  border-radius: 100%;
  height: 4px;
  width: 4px;
  display: inline-block;
  background-color: #313131;
  border-radius: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.blog_detail .blog_detail_date {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.blog_detail h5 {
  color: #313131;
  font-family: "mulishbold";
  /* font-family: "silomregular"; */
  margin: 30px 0px;
}

.blog_detail p {
  color: #313131;
  font-family: "mulishregular";
  margin-bottom: 30px;
}

.blog_detail img {
  margin-bottom: 30px;
  width: 100%;
}

.blog_detail h6 {
  margin-top: 20px;
  font-family: "mulishbold";
  /* font-family: "silomregular"; */
  font-size: 15px;
  color: #313131;
  margin-bottom: 30px;
}

.blog_detail .like_box {
  text-align: center;
}

.blog_detail .like_box button {
  float: none;
  margin: auto;
  color: #313131;
}

.blog_detail .like_box button:hover,
.blog_detail .like_box button:active,
.blog_detail .like_box button:focus {
  color: #313131;
}

.blog_detail .like_box button i {
  font-size: 20px;
}

.blog_detail .like_box button .fas {
  color: #195089;
}

.blog_detail .like_box small {
  font-family: "mulishregular";
  font-size: 12px;
  color: #313131;
}

.blog_detail .like_box p {
  font-family: "mulishlight";
  font-size: 12px;
  color: #313131;
}

.blog_detail .owl-carousel .item {
  padding: 10px;
}

.blog_detail .blog_carousel .blog_card {
  position: relative;
  border: 1px solid #70707063;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.blog_detail .blog_carousel .blog_card img {
  width: 100%;
  margin: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.blog_detail .blog_carousel .blog_card:hover .blog_overlay {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: -o-radial-gradient(transparent, #00000091);
  background: radial-gradient(transparent, #00000091);
}

.blog_detail .blog_carousel .blog_card:hover img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.blog_detail .blog_carousel .blog_card .blog_overlay {
  padding: 30px;
  position: absolute;
  /* background-color: #2c2c2e70; */
  background: -o-radial-gradient(transparent, #000000bf);
  background: radial-gradient(transparent, #000000bf);
  height: 100%;
  width: 100%;
  color: white !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.blog_detail .blog_carousel .blog_card .blog_layer {
  -webkit-transform: translate(-10px, -10px);
      -ms-transform: translate(-10px, -10px);
          transform: translate(-10px, -10px);
  overflow: hidden;
}

.blog_detail .blog_carousel .blog_card .blog_time {
  font-family: "mulishregular";
  font-size: 12px;
}

.blog_detail .blog_carousel .blog_card .blog_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
}

.blog_detail .blog_carousel .blog_card .blog_bottom h6 {
  /* font-family: "silomregular"; */
  font-family: 'mulishsemibold';
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  font-size: 13px;
  color: white !important;
  margin-bottom: 0;
  letter-spacing: 0.5px;
}

.blog_detail .blog_carousel .blog_card .blog_date {
  font-family: "mulishregular";
  font-size: 12px;
}

.blog_detail .blog_carousel {
  /* margin-top: 70px; */
  margin-top: 30px;
  margin-bottom: 90px;
  /* margin-bottom: 100px; */
}

.blog_detail .blog_carousel .owl-carousel .owl-nav {
  display: block;
  position: absolute;
  width: 100%;
  top: 30%;
}

.blog_detail .blog_carousel .owl-carousel .owl-prev {
  left: -50px;
}

.blog_detail .blog_carousel .owl-carousel .owl-next {
  right: -50px;
}

.blog_detail .rotate_heading {
  text-align: center;
  font-family: "mulishbold";
  color: #2c2c2e;
  letter-spacing: 4px;
  font-size: 14px;
  margin-bottom: 0;
  position: absolute;
  left: -248px;
  -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
          transform: rotate(-90deg);
  bottom: 232px;
}

/* End Blog Detail Portion CSS Code Here */