/* Browsers: last 4 version */

/* Start Loader Portion CSS Code Here   */
.theme_loader {
  background-color: #2c2c2e;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  z-index: 9999;
  color: white;
}
.theme_loader .ml10 {
  position: relative;
  /* font-family: "sf_pro_displayheavy_italic"; */
  font-style: italic;
  font-family: 'sf_pro_displaybold';
  color: white;
  text-transform: uppercase;
}

.theme_loader .ml10 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  /* overflow: hidden; */
}

.theme_loader .ml10 .letter {
  display: inline-block;
  line-height: 1em;
  -webkit-transform-origin: 0 0;
      -ms-transform-origin: 0 0;
          transform-origin: 0 0;
}
.theme_loader p{
  color: white;
  font-family: "mulishregular";
  font-size: 13px;
  letter-spacing: 1px;
}
/* End Loader Portion CSS Code Here   */