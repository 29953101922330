/* Browsers: last 4 version */

/* Start Tab Swiper Portion Responsive Media Query CSS Code Here */

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .swiper_content {
    padding-left: 40px;
  }
  .bring_swiper {
    max-width: 330px;
    /* margin-top: 74px; */
    margin-top: 29px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bring_swiper {
    max-width: 100%;
    margin-top: 58px;
  }
  .tabswiper {
    margin-bottom: 50px;
  }
  .tabswiper .nav-tabs {
    width: 450px;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    padding-top: 40px;
    padding-left: 0;
  }
  .tab-content {
    display: unset;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    height: 100%;
  }
  .tabswiper .nav-tabs .nav-item .circle_no {
    top: -29px;
    left: 38px;
  }
  .tabswiper .nav-tabs .nav-item {
    margin-bottom: 30px;
  }
  .tabswiper .nav-tabs .nav-item .nav-link {
    width: 130px;
  }
  .tabswiper .nav-tabs:before {
    content: "";
    /* width: 120%; */
    width: 91%;
    height: 2px;
    background-color: lightgrey;
    position: absolute;
    left: 43px;
    right: 0;
    top: 26px;
  }
  .tabswiper .tabswiper_heading{
    margin-bottom: 50px;
  }
  .tabswiper .nav-tabs .nav-item .nav-link{
    font-size: 15px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .swiper_content {
    padding-left: 0;
  }
  .tabswiper {
    margin-bottom: 50px;
  }
  .tabswiper .nav-tabs {
    /* width: 290px; */
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    padding-top: 40px;
    padding-left: 0;
    margin-top: 30px !important;
    margin: auto;
  }
  .tab-content {
    display: block;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    height: 100%;
    clear: both;
  }
  .tabswiper .nav-tabs .nav-item .circle_no {
    top: -29px;
    left: 38px;
  }
  .tabswiper .nav-tabs .nav-item {
    margin-bottom: 30px;
  }
  .tabswiper .nav-tabs .nav-item .nav-link {
    width: 130px;
  }
  .tabswiper .nav-tabs:before {
    content: "";
    /* width: 540px; */
    width: 411px;
    height: 2px;
    background-color: lightgrey;
    position: absolute;
    left: 43px;
    right: 0;
    top: 26px;
  }
  .bring_swiper {
    max-width: 100%;
    margin-top: 41px;
  }
  .tabswiper .nav-tabs .nav-item .nav-link {
    font-size: 13px;
  }
}
@media only screen and (max-width: 575px) {
  .swiper_content {
    padding-left: 0;
  }
  .tabswiper {
    margin-bottom: 50px;
  }
  .tabswiper .nav-tabs {
    /* width: 290px; */
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    padding-top: 40px;
    padding-left: 0;
    margin-top: 30px !important;
    margin: auto;
  }
  .tab-content {
    display: block;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-orient: unset;
    -webkit-box-direction: unset;
        -ms-flex-direction: unset;
            flex-direction: unset;
    height: 100%;
    clear: both;
  }
  .tabswiper .nav-tabs .nav-item .circle_no {
    top: -29px;
    left: 38px;
  }
  .tabswiper .nav-tabs .nav-item {
    margin-bottom: 30px;
  }
  .tabswiper .nav-tabs .nav-item .nav-link {
    width: 130px;
  }
  .tabswiper .nav-tabs:before {
    content: "";
    /* width: 540px; */
    width: 410px;
    height: 2px;
    background-color: lightgrey;
    position: absolute;
    left: 43px;
    right: 0;
    top: 26px;
  }
  .bring_swiper {
    max-width: 100%;
    margin-top: 41px;
  }
  .tabswiper .nav-tabs .nav-item .nav-link {
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .tabswiper .tabswiper_heading {
    margin-bottom: 0px;
  }
}
/* End Tab Swiper Portion Responsive Media Query CSS Code Here */