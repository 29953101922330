/* Browsers: last 4 version*/

/* Start Case Studies Detail Portion CSS Code Her */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .case_studies_top .case_top:after {
    height: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case_studies_layout .col-lg-4::after {
    content: "";
    width: 1px;
    height: 15px;
    position: absolute;
    right: 0;
    background-color: #707070;
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
    top: 102px;
  }
  .case_studies_top .case_top:after {
    height: 36px;
    bottom: 23px;
  }
  .case_studies_top .case_top {
    margin-bottom: 80px;
  }
  .case_studies_top .case_top h6 {
    max-width: 100%;
  }
  .similar_case_studies {
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .case_studies_top .case_top {
    margin-bottom: 80px;
  }
  .case_studies_top .top_mt h6:nth-child(2) {
    margin: 30px 0px;
  }
  .case_studies_top .case_top:after {
    height: 36px;
    bottom: 23px;
  }
  .case_studies_banner .case_studies_text {
    padding: 15px;
  }
  .case_studies_layout .col-lg-4 {
    margin-bottom: 50px;
  }
  .case_studies_layout .col-lg-4:last-child {
    margin-bottom: 10px;
  }
  .case_studies_layout .col-lg-4:last-child .case_studies_box::after {
    display: none;
  }

  .case_studies_layout .col-lg-4::after {
    display: none;
  }
  .case_studies_layout .case_studies_box::after {
    position: absolute;
    content: "";
    width: 1px;
    height: 20px;
    background-color: #707070;
    bottom: -37px;
  }
  .similar_case_studies .px-0,
  .similar_case_studies .col-lg-4 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .similar_case_studies {
    margin-bottom: 25px;
  }
}
/* End Case Studies Detail Portion CSS Code Her */
