/* Browsers: last 4 version */

/* Start Join Us Portion Responsive Media Query CSS Code Here */

@media only screen and (min-width: 1500px) {
  .joinus_box .join_us_img {
    max-width: 330px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .joinus_box .join_us_para {
    max-width: 340px;
  }

  .joinus_box .join_us_img {
    max-width: 234px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .joinus_box .join_second_img {
    /* max-width: 170px; */
    max-width: 250px;
  }

  .joinus_box .join_first_img {
    max-width: 100%;
  }

  .joinus_box .join_us_para {
    /* max-width: 570px; */
    max-width: 525px;
    margin: auto;
  }

  .job_portion {
    margin-top: 10px;
  }

  .joinus_box .join_us_img {
    max-width: 275px;
    width: 100%;
  }

  .joinus_box .join_first_img {
    /* max-width: 198px; */
    max-width: 250px;
  }

  .joinus_box .join_banner {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 576px) and (max-width: 991px) {
  .joinus_box .job_box .job_list {
    padding: 20px;
  }

  .joinus_box .join_us_para {
    margin-top: 50px !important;
  }
}

@media only screen and (max-width: 575px) {
  .joinus_box .join_us_para h6 {
    margin-left: -27px;
  }
  .joinus_box .job_box .job_list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 15px;
    /* text-align: center; */
  }

  .joinus_box .job_box .job_button {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    height: 50px;
  }

  .joinus_box .job_box .job_button:after {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
  }

  .joinus_box .job_box {
    margin-bottom: 15px;
  }

  .joinus_box .job_box .job_button:hover::after {
    display: none;
  }

  .joinus_box .join_us_para {
    max-width: 238px;
    margin: auto;
    margin-top: 50px !important;
  }

  .joinus_box .join_us_para ul li:before {
    left: -25px;
  }

  .job_portion {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .joinus_box .join_us_img {
    max-width: 130px;
  }

  .joinus_box .join_first_img {
    padding-left: 15px;
    max-width: 120px;
  }

  .joinus_box .join_second_img {
    padding-left: 15px;
    margin-top: 14px;
    max-width: 120px;
  }

  .joinus_box .join_banner {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .joinus_box {
    margin-bottom: 50px;
  }
  .upload_box h5 {
    margin-bottom: 5px;
  }
}

/* End Join Us Portion Responsive Media Query CSS Code Here */
