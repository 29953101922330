/* Browsers: last 4 version */

/* Start Home Page Portion Code Responsive Media Query CSS  */
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .latest_nascent .card_top .card {
    max-width: 16rem;
  }

  .latest_nascent .card_top .card:hover .card_img {
    -webkit-transform: translateY(-126px);
        -ms-transform: translateY(-126px);
            transform: translateY(-126px);
  }
}

@media only screen and (max-width: 991px) {
  #nascent_home .banner_section .banner_heading {
    position: unset;
    height: 100%;
  }

  .collaborate .collaborate_btn {
    margin-bottom: 50px;
  }

  #nascent_home .banner_section {
    padding-bottom: 30px;
  }

  #nascent_home {
    margin-top: 105px;
  }

  #nascent_home .banner_section .banner_heading h3 {
    font-size: 23px;
  }

  #nascent_home .banner_section .contact_us {
    right: -18px;
    top: 132px;
  }

  .latest_nascent .card_top .card {
    margin-bottom: 50px;
  }

  .latest_nascent .card_top .card:hover .card_img {
    -webkit-transform: translateY(-126px);
        -ms-transform: translateY(-126px);
            transform: translateY(-126px);
  }

  .our_team .team_box .team_text {
    bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .our_team .team_box .team_text h4 {
    -webkit-transform: translateY(70px);
        -ms-transform: translateY(70px);
            transform: translateY(70px);
  }

  .our_team .team_box .team_text p {
    margin: 0;
  }

  .community_box .community_inner .community_text {
    padding: 30px;
  }

  /* .community_box .community_inner .community_text p {
    -webkit-line-clamp: 3;
  } */

  .community_box .community_inner .community_text h2 {
    font-size: 25px;
  }

  .collaborate .circle_warning {
    margin: auto;
  }

  .collaborate .collaborate_text p {
    margin: 40px 0;
  }

  #nascent_home .banner_bottom {
    margin-bottom: 50px;
  }

  /* #nascent_home .banner_img {
    margin-bottom: 32px;
} */
  .collaborate {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .collaborate .collaborate_form .form_title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .our_team .team_box h3 {
    font-size: 20px;
  }

  #nascent_home .banner_section .banner_text {
    margin-top: 15px;
  }

  #nascent_home .banner_section .contact_us {
    display: none;
  }

  .latest_nascent .card_top .card:hover .card_img {
    -webkit-transform: translateY(-126px);
        -ms-transform: translateY(-126px);
            transform: translateY(-126px);
  }

  .latest_nascent .see-all {
    margin: 0;
  }

  .case_studies .card {
    margin-bottom: 30px;
    padding: 0;
  }

  .case_studies .case_btn .see_more_btn {
    padding: 0.375rem 0.75rem;
    padding-bottom: 2px;
  }

  .case_studies .case_btn {
    /* margin: 40px 0px; */
    margin: 40px auto;
  }

  .community_box .community_inner .c_box {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }

  .community_box .community_inner {
    margin-left: 0px;
    margin-right: 0px;
  }

  .community_box .community_inner .community_text h2 {
    font-size: 19px;
  }

  .collaborate .circle_warning {
    height: 155px;
    width: 155px;
  }

  .case_studies .c_title {
    margin-bottom: 41px;
  }

  .community_box::after {
    bottom: 34px;
  }

  .our_products p {
    margin: 30px 0px;
  }

  .collaborate {
    padding-bottom: 50px;
  }
}

/* End Home Page Portion Code Responsive Media Query CSS  */