/* Browsers: last 4 version */

/* Start The Company Page Responsive Media Query CSS Code Here  */
@media only screen and (min-width: 1200px) {
  .video_banner .video_btn_container {
    max-width: 1140px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .video_banner .video_btn_container {
    max-width: 960px;
  }
  video {
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .video_banner .video_btn_container {
    max-width: 720px;
  }
  .video_banner .video_btn {
    bottom: 20px;
    left: 15px;
  }
  .video_banner .overlay svg {
    height: 50px;
    width: 50px;
  }
  .vision_box .vision_img {
    max-width: 240px;
    margin-top: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .our_story .p_left {
    padding-right: 0px;
    margin-bottom: 20px;
  }
  .our_story .p_right {
    padding-left: 0px;
  }
  .our_story .p_right {
    /* margin-bottom: 60px; */
    margin-bottom: 90px;
  }
  .values_box h5 {
    margin-bottom: 50px;
  }
  .values_box {
    padding: 70px 0px;
  }
  .client_box {
    margin-bottom: 70px;
  }
  .client_box .client_heading {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .our_story .p_left {
    padding-right: 0px;
    margin-bottom: 20px;
  }
  .our_story .p_right {
    padding-left: 0px;
  }
  .our_story .p_right {
    margin-bottom: 60px;
  }
  .vision_box .vision_img {
    margin: auto;
    margin-top: 50px !important;
  }
  .values_box .v_icon:nth-child(1) {
    margin-bottom: 30px;
  }
  .values_box .v_icon .v_icon_img {
    height: 50px;
    width: 50px;
    margin: auto;
  }
  .our_story .p_right {
    margin-bottom: 60px;
  }
  .vision_box {
    /* margin: 50px 0px; */
    margin-top: 30px;
    margin-bottom: 70px;
  }
  .values_box {
    padding: 70px 0px;
    padding-bottom: 50px;
  }
  .values_box h5 {
    margin-bottom: 50px;
  }
  .our_story h2 {
    margin-top: 50px;
  }
  .client_box .client_heading {
    margin-bottom: 30px;
  }
  .client_box {
    margin-bottom: 30px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .video_banner .video_btn_container {
    max-width: 540px;
  }
  .video_banner .video_btn {
    bottom: 20px;
    left: 15px;
  }
  .video_banner .overlay svg {
    height: 50px;
    width: 50px;
  }
  .video_banner video {
    height: 360px;
  }
}
@media only screen and (min-width: 0px) and (max-width: 575px) {
  .video_banner .video_btn_container {
    max-width: 100%;
  }
  .video_banner .overlay svg {
    height: 40px;
    width: 40px;
  }
  .video_banner .video_btn {
    left: 15px;
    bottom: 15px;
  }
  .video_banner .video_btn_container h6 {
    position: absolute;
    /* top: 139px; */
    /* top: 80%;
    left: 75px;
    font-size: 12px; */

    /* top: 139px; */
    top: 15px;
    left: 15px;
    font-size: 12px;
  }
  .video_banner video {
    height: 200px;
  }
}
/* End The Company Page Responsive Media Query CSS Code Here */
