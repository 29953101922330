/* Browsers: last 4 version */

/* Start Terms & Condition Portion Code Here  */
.terms_content {
  margin-top: 140px;
}
.terms_content .terms_heading {
  color: #2c2c2e;
  font-family: "mulishlight";
  font-size: 13px;
  letter-spacing: 1px;
  margin-bottom: 10px;
}
.terms_content .increment p {
  margin: 35px 0px;
  color: #2c2c2e;
  font-family: "mulishregular";
}

/*  start increment number of p tag css here */
.terms_content .increment div {
  list-style-type: none;
  counter-reset: css-counter 0; /* initializes counter to 0; use -1 for zero-based numbering */
}
.terms_content .increment p {
  counter-increment: css-counter 1; /* Increase the counter by 1. */
}

.terms_content .increment p:before {
  content: counter(css-counter) ". "; /* Apply counter before children's content. */
  margin-right: 15px;
}
/*  End increment number of p tag css here */
/* End Terms & Condition Portion Code Here  */
