/* Browsers: last 4 version */

/* Start Nascent Home Page Layout Portion CSS Code Here */

#nascent_home {
  margin-top: 160px;
}

#nascent_home .banner_section {
  position: relative;
  padding-bottom: 80px;
}

#nascent_home .banner_section .contact_us {
  display: inline-block;
  position: fixed;
  right: 35px;
  top: 189px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  cursor: pointer;
  z-index: 1;
}

#nascent_home .banner_section .contact_us a {
  color: #2c2c2e;
  text-decoration: none;
  font-size: 14px;
  font-family: "noto_sansregular";
}

#nascent_home .banner_section .banner_heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* height: 100%; */
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 1;
}

.textFadeout {
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: -1 !important;
}

#nascent_home .banner_section .banner_heading h1 {
  font-family: "silomregular";
  color: #313131;
}

#nascent_home .banner_section .banner_heading h3 {
  font-family: "silomregular";
  margin-bottom: 0px;
  color: #313131;
}

#nascent_home .banner_section .banner_text {
  font-family: "mulishregular";
  line-height: 21px;
  max-width: 464px;
  width: 100%;
  margin-bottom: 0;
}

#nascent_home .banner_section .banner_text span {
  font-family: "mulishbold";
}

/* #nascent_home .banner_img {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 45px;
} */

#nascent_home .banner_bottom {
  /* margin-bottom: 150px; */
  margin-bottom: 100px;
}

#nascent_home .banner_bottom .arrow_move {
  text-align: center;
  padding-top: 20px;
}

#nascent_home .banner_bottom .arrow_move svg {
  display: inline-block;
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

#nascent_home .banner_bottom .arrow_move:hover svg {
  -webkit-transform: translateX(20%);
  -ms-transform: translateX(20%);
  transform: translateX(20%);
  -webkit-transition: all 0.5s ease-in;
  -o-transition: all 0.5s ease-in;
  transition: all 0.5s ease-in;
}

#nascent_home .banner_bottom p {
  font-family: "mulishregular";
  line-height: 21px;
}

/* Start Latest From Nascent Portion CSS Code Here */

.latest_nascent {
  background-color: #2c2c2e;
  /* margin-bottom: 150px; */
  /* margin-bottom: 90px; */
  margin-bottom: 105px;
  padding-top: 90px;
  padding-bottom: 110px;
}

/* .latest_nascent.nascent_pb{
  padding-bottom: 90px;
} */

.latest_nascent .latest_nascent_heading h1 {
  color: white;
  font-family: "silomregular";
  margin-bottom: 0;
}

.latest_nascent .latest_nascent_heading h3 {
  color: white;
  font-family: "silomregular";
}

.latest_nascent .latest_ntext {
  color: white;
  font-family: "mulishregular";
  margin-top: 40px;
  padding-right: 80px;
  position: relative;
}

/* .latest_nascent .latest_ntext span {
  font-family: "mulishbold";
} */

.latest_nascent .first_child::after {
  content: "";
  left: 0;
  width: 1px;
  height: 24px;
  background-color: #ffffff91;
  position: absolute;
  bottom: -30px;
}

.latest_nascent .nascent_slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 130px;
  width: 100%;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
}

.latest_nascent .nascent_slider p {
  color: white;
  margin: 0;
  text-transform: uppercase;
  font-family: "mulishlight";
  font-size: 14px;
}

.latest_nascent .nascent_slider svg {
  width: 16px;
}
.latest_nascent .nascent_slider .btn:disabled {
  cursor: not-allowed;
}

/* End Latest From Nascent Portion CSS Code Here */

.latest_nascent .card_top {
  margin-top: 100px;
}

.latest_nascent .card_top .card {
  background-color: transparent;
  border-color: #ffffffb0;
  border-radius: 0;
  max-width: 18rem;
  margin: auto;
  cursor: pointer;
}

.latest_nascent .card_top .card_detail {
  overflow: hidden;
  -webkit-transform: translate(-10px, -10px);
  -ms-transform: translate(-10px, -10px);
  transform: translate(-10px, -10px);
  background-color: white;
  height: 395px;
  position: relative;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .card_detail .read_more {
  position: absolute;
  bottom: 30px;
  -webkit-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  left: 50%;
  font-family: "silomregular";
  color: white;
  font-size: 13px;
  text-decoration: none;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .card_top .card_detail .card_text {
  padding: 30px;
}

.latest_nascent .card_top .card_detail .card_text h6 {
  font-family: "silomregular";
  color: #313131;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.latest_nascent .card_top .card .card_img {
  width: auto;
  height: 395px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .card_top .card .card_img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.latest_nascent .card_top .card:hover .card_img {
  width: auto;
  height: 395px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(-126px);
  -ms-transform: translateY(-126px);
  transform: translateY(-126px);
}

.latest_nascent .card_top .card:hover .card_img img {
  height: 100%;
}

.latest_nascent .see-all {
  text-align: center;
  margin-top: 50px;
  /* margin: 50px auto; */
}

.latest_nascent .card:hover .read_more {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .see-all .see_btn {
  text-transform: uppercase;
  font-family: "mulishlight";
  font-size: 14px;
  border-bottom: 1px solid #ffffffb0;
  border-radius: 0;
  display: inline-block;
  padding: 0;
  opacity: 0.5;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .see-all .see_btn:hover {
  opacity: 1;
}

/* Start Transform, Believe, Innovate Case Studies Card Portion CSS Code Here  */

.case_studies .c_title {
  text-align: center;
  font-family: "silomregular";
  color: #313131;
  margin-bottom: 70px;
  /* margin-bottom: 90px; */
}

.case_studies .case_overlay {
  position: relative;
  overflow: hidden;
  border: 1px solid transparent;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /* text-align: center; */
  cursor: pointer;
}

.case_studies .case_overlay .c_overlay {
  background-color: #19508930;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies .case_overlay h5 {
  letter-spacing: 0.5px;
  color: white;
  text-align: center;
  /* color: #313131; */
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: absolute;
  bottom: 20px;
  font-family: "mulishsemibold";
  /* font-family: "mulishbold"; */
  /* font-family: "silomregular"; */
  font-size: 13px;
  padding: 0 35px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.case_studies .case_overlay h5 .c_thin {
  font-family: "mulishlight";
  margin-right: 5px;
  text-transform: uppercase;
}

.case_studies .case_overlay h5 span {
  text-transform: uppercase;
}

.case_studies .card:hover .c_overlay {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies .card:hover h5 {
  /* opacity: 0; */
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /* color: white; */
}

.case_studies .card:hover .case_overlay {
  /* border: 1px solid #707070; */
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies .card:hover img {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies .card img {
  /* transform: scale(1.05); */
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.case_studies .card .card-body .card_tag {
  font-family: "mulishregular";
  font-size: 13px;
  color: #2c2c2e;
  text-align: center;
  margin-bottom: 1.1rem;
}

.case_studies .card .card-body .card_tag span {
  position: relative;
  margin: 0 13px;
  display: inline-block;
}

.case_studies .card .card-body .card_tag span::after {
  content: "";
  position: absolute;
  right: -15px;
  height: 15px;
  width: 1px;
  background-color: #313131;
}

.case_studies .card .card-body .card_tag span:last-child:after {
  display: none;
}

.case_studies .card .card-body .card-text {
  font-family: "mulishregular";
  color: #2c2c2e;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.case_studies .card {
  border-color: transparent;
}

.case_studies .case_btn {
  border: 1px solid #313131;
  margin: auto;
  max-width: 245px;
}

.case_studies .case_btn .see_more_btn:hover {
  background-color: #fdc02d !important;
}

.case_studies .case_btn .see_more_btn {
  border-radius: 0;
  background-color: white !important;
  color: #2c2c2e;
  font-family: mulishregular;
  font-size: 12px;
  letter-spacing: 1px;
  text-align: left;
  text-transform: uppercase;
  -webkit-transform: translate(-5px, -5px);
  -ms-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
  width: 243px;
  border: 1px solid #ffc014;
  padding: 5px 20px;
  /* color: #2c2c2e;
  font-family: "mulishlight";
  font-size: 13px;
  border: 1px solid #ffc014;
  border-radius: 0;
  padding-bottom: 2px;
  padding-left: 30px;
  padding-right: 30px; */
}

.case_studies .case_btn .see_more_btn:active {
  color: #2c2c2e;
  border-color: #ffc014;
}

.case_studies .case_btn {
  text-align: center;
  /* margin: 80px 0px; */
  margin-top: 30px;
  /* margin-bottom: 70px; */
  margin-bottom: 90px;
}

.case_studies .case_btn .see_more_btn svg {
  margin-left: 30px;
  margin-top: -5px;
}

.case_studies .card {
  padding-left: 15px;
  padding-right: 15px;
}

/* End Transform, Believe, Innovate Case Studies Card Portion CSS Code Here  */

/* Start Our Tem & Join Us Portion CSS Code Here  */

.our_team {
  cursor: pointer;
}

.our_team .team_box img {
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.our_team .col-md-6 {
  padding: 0;
}

.our_team .team_box {
  position: relative;
  overflow: hidden;
}

.our_team .team_box .team_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #1950894f;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.our_team .team_box:hover .team_overlay {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* .our_team .team_box:hover img {
  transform: scale(1.02);
  transition: all 0.5s ease-in-out;
} */

.our_team .team_box h3 {
  font-family: "mulishlight";
  /* font-family: "mulishregular"; */
  /* font-family: "mulishbold"; */
  font-size: 22px;
}

.our_team .team_box .team_text {
  position: absolute;
  bottom: 75px;
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
}

.our_team .team_box .team_text h4 {
  color: white;
  font-family: "silomregular";
  -webkit-transform: translateY(91px);
  -ms-transform: translateY(91px);
  transform: translateY(91px);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.our_team .team_box .team_text p {
  color: white;
  font-family: "mulishregular";
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  margin: 0;
}

.our_team .team_box:hover .team_text h4 {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  transform: translateY(-15px);
}

.our_team .team_box:hover .team_text p {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* End Our Tem & Join Us Portion CSS Code Here  */

/* Start Know Your Community Portion CSS Code Here */

.community_box {
  position: relative;
  padding-top: 90px;
  padding-bottom: 200px;
}

.community_box::after {
  content: "";
  height: 100px;
  width: 1px;
  background-color: #2c2c2e;
  display: block;
  position: absolute;
  bottom: 60px;
  opacity: 0.5;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.community_box .community_inner {
  border: 1px solid #313131;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  cursor: pointer;
}

.community_box .community_inner .community_img img {
  width: 100%;
}

.community_box .community_inner .c_box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.community_box .community_inner .community_text:last-child {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.community_box .community_inner .community_text {
  padding: 50px;
  position: relative;
}

.community_box .community_inner .community_text p {
  /* -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    display: -webkit-box !important;
    overflow: hidden; */
  color: #2c2c2e;
  font-family: "mulishregular";
}

.community_box .community_inner .community_text h2 {
  color: #2c2c2e;
  font-family: "silomregular";
  margin-bottom: 24px;
}

.community_box .community_inner .community_text .circle {
  height: 60px;
  width: 60px;
  background-color: #2c2c2e;
  border-radius: 100%;
  position: absolute;
  bottom: 0px;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.community_box .community_inner .community_text .circle .eye_box {
  position: relative;
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.community_box .community_inner .community_text .circle:hover .openeye {
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.community_box .community_inner .community_text .circle:hover .closeeye {
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.closeeye {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  opacity: 1;
  position: absolute;
  width: 21px;
  height: 20px;
  top: 23px;
}

.openeye {
  -webkit-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  position: absolute;
  width: 30px;
  height: 30px;
  opacity: 0;
}

/* End Know Your Community Portion CSS Code Here */

/* Start Collaborate With Us Portion CSS Code Here  */

.collaborate {
  padding-top: 30px;
  padding-bottom: 90px;
}

.collaborate .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.collaborate .collaborate_text p {
  font-family: "mulishregular";
  margin: 0;
}

.collaborate h3 {
  font-family: "silomregular";
  margin-bottom: 0px;
  color: #313131;
}

.collaborate h1 {
  font-family: "silomregular";
  margin-bottom: 0px;
  color: #313131;
}

.collaborate .circle_warning {
  cursor: pointer;
  border: 1px solid #ffc014;
  height: 180px;
  width: 180px;
  margin-left: auto;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.collaborate .circle_warning:hover {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: rotate(-10deg);
  -ms-transform: rotate(-10deg);
  transform: rotate(-10deg);
}

.collaborate .circle_warning p {
  margin: 0;
  font-family: "mulishregular";
  color: #0059b3;
  max-width: 120px;
  width: 100%;
}

.collaborate .circle_warning p span {
  display: block;
  font-family: "mulishbold";
}

.collaborate .collaborate_text {
  margin-bottom: 20px;
}

.collaborate .collaborate_btn {
  border: 1px solid #313131;
  max-width: 147px;
}

.collaborate .collaborate_btn button:hover,
.collaborate .collaborate_btn button:focus,
.collaborate .collaborate_btn button:active {
  background-color: #fdc02d !important;
  color: #2c2c2e !important;
  border-color: #fdc02d !important;
}

.collaborate .collaborate_btn button {
  color: #2c2c2e;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  background-color: white !important;
  border: 1px solid #fdc02d;
  /* background-color: #fdc02d !important; */
  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
  -ms-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
  width: 145px;
}

.collaborate .collaborate_form label {
  color: #313131 !important;
  font-family: "mulishregular";
  font-size: 14px;
}

.collaborate .collaborate_form .error {
  color: red !important;
  margin-bottom: 0;
}

.collaborate .collaborate_form .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #31313187;
  border-radius: 0;
}

.collaborate .collaborate_form textarea {
  width: 100%;
  border-radius: 0;
  border-color: #31313187;
  padding: 15px;
  font-size: 14px;
  color: #495057;
}

.collaborate .collaborate_form textarea:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.collaborate .collaborate_form button {
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  border: 1px solid #fdc02d;
  background-color: #fdc02d !important;
  border-radius: 0;
  width: 145px;
  text-align: center;
  margin-top: 20px;
}

.collaborate .collaborate_form .react-tel-input .flag-dropdown {
  background-color: transparent;
  border: 0;
}

.collaborate .collaborate_form .react-tel-input .flag-dropdown .selected-flag {
  background-color: transparent;
}

.collaborate
  .collaborate_form
  .react-tel-input
  .flag-dropdown
  .selected-flag:hover,
.collaborate
  .collaborate_form
  .react-tel-input
  .flag-dropdown
  .selected-flag:focus {
  background-color: transparent;
}

.collaborate .collaborate_form .row {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.collaborate .collaborate_form .form_title {
  color: #313131;
  text-align: center;
  margin-bottom: 70px;
  font-family: "mulishsemibold";
}

/* End Collaborate With Us Portion CSS Code Here  */

/* End Nascent Home Page Layout Portion CSS Code Here */
