/* Browsers: last 4 version */

/* Start Error Page CSS Code  */
.error-page h1 {
  text-align: center;
  font-size: 50px;
}

.error-page .msg {
  text-align: center;
}

.error-page {
  margin: 250px 0px;
}

.error-page span {
  font-size: 120px;
  font-weight: 700;
  color: gray;
}
/* End Error Page CSS Code  */
