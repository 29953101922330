/* Browsers: last 4 version */

/* Start Insights Heading Portion CSS Code Here */
.insight_heading {
  margin-top: 140px;
}
.insight_heading .heading {
  color: #313131;
  font-family: "silomregular";    
  margin-left: -5px;
}
.insight_heading .sub_heading {
  color: #313131;
  font-family: "mulishlight";
  font-size: 13px;
  letter-spacing: 1px;
}
/* End Insights Heading Portion CSS Code Here */
