/* Browsers: last 4 version */

/* Start Privacy Policy Portion CSS Code Here */
.privacy_policy .privacy_heading {
  color: #313131;
  font-family: "mulishlight";
  margin-bottom: 50px;
  font-size: 13px;
  letter-spacing: 1px;
}
.privacy_policy {
  margin-top: 115px;
}
.privacy_policy .even {
  background-color: #f5f5f5;
}
.privacy_policy ul li h6 {
  color: #2c2c2e;
  font-family: "mulishbold";
}
.privacy_policy ul p {
  color: #2c2c2e;
  font-family: "mulishregular";
}
.privacy_policy ul span {
  color: #2c2c2e;
  font-family: "mulishbold";
  margin-right: 15px;
}
.privacy_policy .first_ul {
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  list-style: none;
  margin: 40px 0px;
}
.privacy_policy .first_ul ul {
  margin-bottom: 30px;
}
.privacy_policy .first_ul li {
  margin-bottom: 20px;
}
.privacy_policy .first_ul ul li ul {
  margin-top: 20px;
}
.privacy_policy .first_ul ul li ul li {
  margin-bottom: 20px;
}
.privacy_policy ul {
  /* padding-inline-start: 0; */
  list-style: none;
}
.privacy_policy ul p {
  margin: 0;
}
/* End Privacy Policy Portion CSS Code Here */
