/* Browsers: last 4 version */

html {
  scroll-behavior: smooth;
}

/* width */
/* ::-webkit-scrollbar {
    width: 7px;
  } */

/* Track */
/* ::-webkit-scrollbar-track {
    background: #3131314d;
    border-radius: 5px;
  } */

/* ::-webkit-scrollbar-thumb {
    background: #0059b3;
    border-radius: 5px;
  } */

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #ffc014;
  } */

p {
  color: #2c2c2e;
  line-height: 21px;
  font-size: 14px;
}

.btn:focus,
.btn:active,
.btn {
  background-color: transparent !important;
  outline: 0;
  border: transparent;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.tab-pane {
  color: white;
  width: 100%;
}

.owl-theme .owl-dots .owl-dot span {
  background-color: #4a4a4a82 !important;
  width: 30px !important;
  height: 3px !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #313131 !important;
}

/* Start Common Modal CSS Code Here  */
.modal {
  border-radius: 0;
}

.modal .modal-content {
  border-radius: 0;
  border: 0;
}

.modal .card-title {
  color: #313131;
  font-family: "mulishsemibold";
  font-size: 18px;
}

.modal .card-text {
  color: #313131;
  font-family: "mulishregular";
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.modal .react-tel-input .form-control {
  width: 100%;
}

.modal .apply_btn {
  background-color: #fdc02d !important;
  display: block;
  color: #2c2c2e;
  border-radius: 0;

  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  width: 153px;
  margin: 15px auto;
}

.modal .apply_btn:active {
  color: #2c2c2e !important;
}

.modal-header {
  background-color: #313131;
  color: white;
  font-family: "mulishsemibold";
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 30px;
  /* padding: 10px 1rem; */
  border-radius: 0;
}

.modal-header .modal-title {
  font-size: 16px;
}

.modal label {
  color: #313131 !important;
  font-family: "mulishregular";
  font-size: 14px;
}

.modal .error {
  color: red !important;
  margin-bottom: 0;
}

.modal .form-control {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1.5 !important;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #31313187 !important;
  border-radius: 0 !important;
}

.modal textarea {
  width: 100%;
  border-radius: 0;
  border-color: #31313187;
  padding: 15px;
  font-size: 14px;
}

.modal textarea:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.modal .react-tel-input .flag-dropdown {
  background-color: transparent !important;
  border: 0;
}

.modal .react-tel-input .flag-dropdown .selected-flag {
  background-color: transparent !important;
}

.modal .react-tel-input .flag-dropdown .selected-flag:hover,
.modal .react-tel-input .flag-dropdown .selected-flag:focus {
  background-color: transparent !important;
}

.modal .row {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
}

.modal .modal-header .btn {
  padding-right: 0;
}

select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-image: url("./components/Home/ourproducts/caret.png");
  background-repeat: no-repeat;
  background-position: 97% center;
  background-size: 15px;
  font-family: "mulishregular";
}

.modal .modal-body {
  padding: 30px !important;
}

/* End Common Modal CSS Code Here  */

@media only screen and (min-width: 1500px) {
  .container {
    max-width: 1320px !important;
  }

  p {
    font-size: 15px !important;
  }

  header .navbar-nav .nav-link,
  header .navbar-nav .dropdown .dropdown-item {
    font-size: 15px !important;
  }

  .latest_nascent .latest_tabs .tab_heading h6 {
    font-size: 16px !important;
  }

  .case_studies .card .card-body .card_tag {
    font-size: 14px !important;
  }

  .collaborate .collaborate_form label {
    font-size: 15px !important;
  }

  footer .footer_link ul li a {
    font-size: 15px !important;
  }

  footer .footer_detail .footer_data span p {
    font-size: 15px !important;
  }

  footer .footer_detail .email_mobile a {
    font-size: 15px !important;
  }

  footer .footer_media .form-label {
    font-size: 15px !important;
  }

  footer .footer_media .followus .form-label {
    font-size: 15px !important;
  }

  footer .copyright p {
    font-size: 15px !important;
  }

  .timeline_carousel .timeline_status a {
    font-size: 15px !important;
  }

  .testimonial_carousel .testimonial_descp h6 {
    font-size: 16px !important;
  }

  .testimonial_carousel .testimonial_descp span {
    font-size: 15px !important;
  }

  .digital_transform h6 {
    font-size: 16px !important;
  }

  .service_transform .transform_box .overlay h6 {
    font-size: 16px !important;
  }

  .blog_content .blog_card .blog_time {
    font-size: 13px !important;
  }

  .blog_content .blog_card .blog_date {
    font-size: 13px !important;
  }

  .insights_tab .nav-pills .nav-link {
    font-size: 16px !important;
  }

  .papers_content .papers_card .papers_text h6 {
    font-size: 14px !important;
  }

  .papers_detail .papers_card_box .paper_text p {
    font-size: 13px !important;
  }

  .papers_detail .papers_card_box .paper_text h6 {
    font-size: 14px !important;
  }

  .papers_detail .papers_card_box .papers_descp h6 {
    font-size: 14px !important;
  }

  .papers_detail .papers_card_box .papers_descp p {
    font-size: 13px !important;
  }

  .papers_detail .paper_detl_card .papers_text h6 {
    font-size: 14px !important;
  }

  .social_content .social_content_box span {
    font-size: 13px !important;
  }

  .social_content .social_content_box .social_text p {
    font-size: 14px !important;
  }

  .case_studies_home .case_tab ul li a {
    font-size: 15px !important;
  }

  .case_studies_home .card .card-body .card_tag {
    font-size: 14px !important;
  }

  .case_studies_home .case_overlay h5 {
    font-size: 14px !important;
  }

  .joinus_box .join_us_para ul li {
    font-size: 15px !important;
  }

  .joinus_box .job_box button {
    font-size: 13px !important;
  }

  .upload_box span {
    font-size: 17px !important;
  }

  .privacy_policy ul li h6 {
    font-size: 17px !important;
  }

  .latest_nascent .tab_box .social_content .tab_heading h6 {
    font-size: 16px !important;
  }

  .case_studies_top .case_top .badge {
    font-size: 15px !important;
  }
}

@media only screen and (max-width: 991px) {
  .owl-carousel .owl-dots {
    display: block !important;
  }

  .owl-carousel .owl-nav {
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .modal .modal-body {
    padding: 1rem !important;
  }

  .modal-header {
    padding: 1rem;
  }
}

@media only screen and (min-width: 576px) {
  .modal-sm {
    max-width: 345px !important;
  }
}
