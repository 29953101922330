/* Browsers: last 4 version */

/* Start Video Banner Portion CSS Code Here   */

.video_banner {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}

video {
  /* height: auto;
  width: 100%;
  margin-bottom: -6px; */
  height: 100vh;
  width: 100vw;
  margin-bottom: -6px;
  -o-object-fit: cover;
  object-fit: cover;
}

.overlay-transparent {
  background-color: transparent !important;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.video_banner .overlay {
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000082;
  /* background-color: #000000; */
  /* opacity: 0.5; */
  height: 100%;
  width: 100%;
  z-index: 1;
}

.video_banner .video_btn {
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgb(255 255 255);
  box-shadow: 0 0 0 0 rgb(255 255 255);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
  bottom: 50px;
  border-radius: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  /* left: 0px; */
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
    -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.video_banner .video_btn_container h6 {
  position: absolute;
  top: 110px;
  color: white;
  z-index: 2;
  text-transform: uppercase;
  font-family: "mulishlight";
  letter-spacing: 3.6px;
  font-size: 14px;
}

.video_banner .overlay svg {
  cursor: pointer;
  height: 60px;
  width: 60px;
}

.video_banner .hide {
  display: none;
}

.video_banner .video_btn_container {
  margin: auto;
  position: relative;
  height: 100%;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

/* End Video Banner Portion CSS Code Here   */

/* Start Our Story Portion CSS Code Here */

.our_story h2 {
  font-family: "silomregular";
  margin: 50px 0px;
  margin-top: 90px;
  color: #313131;
}

.our_story p {
  color: #313131;
  font-family: "mulishregular";
}

.our_story .p_left {
  padding-right: 70px;
  margin-bottom: 90px;
}

.our_story .p_right {
  padding-left: 70px;
  margin-bottom: 90px;
}

.our_story img {
  width: 100%;
}

.our_story .col-md-4 {
  padding: 0;
}

.our_story .img_caption p {
  font-family: "mulishregular";
  /* font-family: 'mulishsemibold'; */
}

/* End Our Story Portion CSS Code Here */

/* Start We Have a vision Portion CSS Code Here */

.vision_box {
  /* margin: 70px 0px; */
  margin-top: 50px;
  margin-bottom: 90px;
}

.vision_box img {
  width: 100%;
}

.vision_box p {
  color: #313131;
  font-family: "mulishlight";
  margin-bottom: 35px;
  margin-top: 15px;
  font-size: 13px;
  letter-spacing: 1px;
}

.vision_box .vision_img {
  max-width: 260px;
  margin-left: auto;
  margin-top: 15px;
}

.vision_box h5 {
  color: #313131;
  font-family: "silomregular";
}

.vision_box h5 span {
  color: #195089;
}

.vision_box h6 {
  font-family: "mulishsemibold";
}

.vision_box h6 {
  margin-bottom: 20px;
}

/* End We Have a vision Portion CSS Code Here */

/* Start Values we thirive on Portion CSS Code Here */

.values_box {
  background-color: #2c2c2e;
  /* padding: 70px 0px; */
  padding: 90px 0px;
}

.values_box h5 {
  color: white;
  text-align: center;
  font-family: "mulishbold";
  /* font-family: "silomregular"; */
  margin-bottom: 70px;
}

.values_box .v_icon p {
  color: white;
  font-family: "mulishmedium";
  text-align: center;
  margin-top: 30px;
}

.values_box .v_icon .v_icon_img {
  height: 75px;
  width: 75px;
  margin: auto;
}

.values_box .v_icon .v_icon_img img {
  width: 100%;
}

.client_box {
  /* margin-bottom: 115px; */
  /* margin-bottom: 90px; */
  /* margin-bottom: 125px; */
  margin-bottom: 140px;
}

.client_box .client_heading {
  /* margin-bottom: 90px; */
  /* margin-bottom: 70px; */
  margin-bottom: 75px;
}

.client_box .client_heading h2 {
  color: #313131;
  text-align: center;
  font-family: "silomregular";
}

.client_box img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  width: 100%;
}

.client_box img:hover {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}

.mystyle {
  background-color: transparent;
}

.hidden {
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* End Values we thirive on Portion CSS Code Here */
