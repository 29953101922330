/* Start Font Family CSS Code  */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 11, 2023 */

@font-face {
  font-family: "mulishregular";
  src: url("../fontfamily/mulish-regular-webfont.eot");
  src: url("../fontfamily/mulish-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fontfamily/mulish-regular-webfont.woff2") format("woff2"),
    url("../fontfamily/mulish-regular-webfont.woff") format("woff"),
    url("../fontfamily/mulish-regular-webfont.ttf") format("truetype"),
    url("../fontfamily/mulish-regular-webfont.svg#mulishregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mulishmedium';
  src: url('../fontfamily/mulish-medium-webfont.eot');
  src: url('../fontfamily/mulish-medium-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fontfamily/mulish-medium-webfont.woff2') format('woff2'),
    url('../fontfamily/mulish-medium-webfont.woff') format('woff'),
    url('../fontfamily/mulish-medium-webfont.ttf') format('truetype'),
    url('../fontfamily/mulish-medium-webfont.svg#mulishmedium') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: "mulishitalic";
  src: url("../fontfamily/mulish-italic-webfont.eot");
  src: url("../fontfamily/mulish-italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fontfamily/mulish-italic-webfont.woff2") format("woff2"),
    url("../fontfamily/mulish-italic-webfont.woff") format("woff"),
    url("../fontfamily/mulish-italic-webfont.ttf") format("truetype"),
    url("../fontfamily/mulish-italic-webfont.svg#mulishitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mulishsemibold';
  src: url('../fontfamily/mulish-semibold-webfont.eot');
  src: url('../fontfamily/mulish-semibold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fontfamily/mulish-semibold-webfont.woff2') format('woff2'),
    url('../fontfamily/mulish-semibold-webfont.woff') format('woff'),
    url('../fontfamily/mulish-semibold-webfont.ttf') format('truetype'),
    url('../fontfamily/mulish-semibold-webfont.svg#mulishsemibold') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: "mulishbold";
  src: url("../fontfamily/mulish-bold-webfont.eot");
  src: url("../fontfamily/mulish-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fontfamily/mulish-bold-webfont.woff2") format("woff2"),
    url("../fontfamily/mulish-bold-webfont.woff") format("woff"),
    url("../fontfamily/mulish-bold-webfont.ttf") format("truetype"),
    url("../fontfamily/mulish-bold-webfont.svg#mulishbold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'mulishlight';
  src: url('../fontfamily/mulish-light-webfont.eot');
  src: url('../fontfamily/mulish-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fontfamily/mulish-light-webfont.woff2') format('woff2'),
    url('../fontfamily/mulish-light-webfont.woff') format('woff'),
    url('../fontfamily/mulish-light-webfont.ttf') format('truetype'),
    url('../fontfamily/mulish-light-webfont.svg#mulishlight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'silomregular';
  src: url('../fontfamily/silom-webfont.eot');
  src: url('../fontfamily/silom-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fontfamily/silom-webfont.woff2') format('woff2'),
    url('../fontfamily/silom-webfont.woff') format('woff'),
    url('../fontfamily/silom-webfont.ttf') format('truetype'),
    url('../fontfamily/silom-webfont.svg#silomregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'noto_sansregular';
  src: url('../fontfamily/notosans-regular-webfont.eot');
  src: url('../fontfamily/notosans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fontfamily/notosans-regular-webfont.woff2') format('woff2'),
    url('../fontfamily/notosans-regular-webfont.woff') format('woff'),
    url('../fontfamily/notosans-regular-webfont.ttf') format('truetype'),
    url('../fontfamily/notosans-regular-webfont.svg#noto_sansregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'sf_pro_displaybold';
  src: url('../fontfamily/sfprodisplaybold-webfont.eot');
  src: url('../fontfamily/sfprodisplaybold-webfont.eot?#iefix') format('embedded-opentype'),
    url('../fontfamily/sfprodisplaybold-webfont.woff2') format('woff2'),
    url('../fontfamily/sfprodisplaybold-webfont.woff') format('woff'),
    url('../fontfamily/sfprodisplaybold-webfont.ttf') format('truetype'),
    url('../fontfamily/sfprodisplaybold-webfont.svg#sf_pro_displaybold') format('svg');
  font-weight: normal;
  font-style: normal;

}

/* End Font Family CSS Code  */