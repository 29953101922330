/* Browsers: last 4 version */

/* Start Nascent Latest Tab Content Portion CSS Code Here */
.latest_nascent .latest_tabs {
  max-width: 625px;
  width: 100%;
}

.latest_nascent .latest_tabs .tab-content>.tab-pane {
  visibility: hidden !important;
  /* transition: all 0.15s ease-in-out;
  animation: fadeIn 0.9s forwards; */
  cursor: pointer;
  -webkit-animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.latest_nascent .latest_tabs .tab-content>.active {
  visibility: visible !important;
  /* transition: all 0.15s ease-in-out; */
  -webkit-animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: fade-in 3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2023-10-23 11:6:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.latest_nascent .latest_tabs .nav-tabs .nav-link {
  background-color: transparent;
  border: 1px solid transparent;
  color: white;
  font-family: "mulishregular";
  padding-bottom: 20px;
  font-size: 14px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  opacity: 0.5;
}

.latest_nascent .latest_tabs .nav-tabs {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #afb2b640;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.latest_nascent .latest_tabs .nav-tabs .nav-link.active {
  border-bottom: 1px solid white;
  font-family: "mulishbold";
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .latest_tabs .nav-tabs .nav-item {
  width: 130px;
}

.latest_nascent .latest_tabs .tab_heading {
  padding: 30px;
}

.latest_nascent .latest_tabs .tab_heading h6 {
  color: #2c2c2e;
  font-size: 15px;
  margin-bottom: 12px;
  font-family: "mulishbold";
  /* font-family: "silomregular"; */
}

.latest_nascent .latest_tabs .tab_heading p {
  font-family: "mulishsemibold";
  margin-bottom: 0;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.latest_nascent .latest_tabs .tab_content {
  /* clip-path: polygon(0% 0%, 93% 0, 109% 19%, 100% 100%, 0% 100%); */
  background-color: white;
  margin-top: 50px;
  position: relative;
}

.latest_nascent .latest_tabs .tab_content:hover::before {
  border-width: 0 0px 0px 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.latest_nascent .latest_tabs .tab_content:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  border-width: 0 30px 30px 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  border-style: solid;
  border-color: #ffc900 #2c2c2e;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3), -1px 1px 1px rgba(0, 0, 0, 0.2);
}

.latest_nascent .latest_tabs .corner_remove .tab_content::before {
  display: none;
}

.latest_nascent .tab_box {
  position: relative;
}

.latest_nascent .tab_box .download_button {
  position: absolute;
  right: 0px;
  top: 102px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.latest_nascent .tab_box .download_button a:first-child {
  margin-bottom: 50px;
}

.latest_nascent .tab_box .download_button svg {
  width: 25px;
}

.latest_nascent .tab_box .social_content {
  margin-top: 50px;
  position: relative;
  border: 1px solid transparent;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.latest_nascent .tab_box .social_content:hover {
  border: 1px solid #ffc900;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.latest_nascent .tab_box .social_content .tab_heading h6 {
  font-size: 15px;
  margin-bottom: 12px;
  color: white;
  font-family: "mulishbold";
  /* font-family: "silomregular"; */
}

.latest_nascent .tab_box .social_content .tab_heading p {
  font-family: "mulishsemibold";
  margin-bottom: 0;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  color: white;
}

.latest_nascent .tab_box .social_content .tab_heading {
  position: absolute;
  top: 0;
  width: 100%;
}

/* End Nascent Latest Tab Content Portion CSS Code Here */