/* Browsers: last 4 version */

/* Start Header Portion Code CSS  */
header {
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 9;
  width: 100%;
  padding: 23px 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: white;
}

header.visible {
  /* top: 0px !important;
  position: fixed; */
  -webkit-transform: translateY(0px);
  -ms-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

header.hidden {
  /* top: -84px !important; */
  -webkit-transform: translateY(-83px);
  -ms-transform: translateY(-83px);
  transform: translateY(-83px);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

header .nascent_logo {
  background: url("./nascent-logo-h-2.png") no-repeat;
  background-position: right bottom;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  vertical-align: top;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  font-size: unset;
  margin-right: 0px;
}

header .nascent_logo:hover {
  background-position: right top;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

header .nascent_logo img {
  vertical-align: top;
}

header .nav-item.active .nav-link {
  font-family: "mulishbold" !important;
  color: #313131;
}

header .navbar-nav .nav-link {
  color: #6a6a6a;
  padding-left: 1.3rem !important;
  padding-right: 1.3rem !important;
  font-size: 14px;
  font-family: "mulishregular" !important;
}

header .navbar-nav .dropdown .dropdown-item {
  font-size: 14px;
  font-family: "mulishlight";
  padding: 10px 1.5rem;
  color: #2c2c2e;
}

header .navbar-nav .dropdown .dropdown-item:focus,
header .navbar-nav .dropdown .dropdown-item:hover {
  background-color: #f0f0f0;
  color: #2c2c2e;
}

header .navbar-nav .dropdown .dropdown-menu {
  border: 0;
  -webkit-box-shadow: 0px 5px 19px #00000029;
  box-shadow: 0px 5px 19px #00000029;
  border-radius: 0;
}

header .navbar-nav .dropdown .dropdown-menu {
  padding: 0;
}

header .navbar-nav .dropdown .btn-primary .fa-search {
  color: #2c2c2e;
}

.search_modal.modal-dialog {
  max-width: 100%;
  margin: 0;
}

.search_modal .modal-body {
  padding: 1rem !important;
}

.search_modal .modal-content {
  border-radius: 0;
  background-color: #2c2c2e;
  height: 83px;
  border: 0;
}

.search_modal .search_box {
  position: relative;
  margin-top: 11px;
}

.search_modal .search_button {
  position: absolute;
  right: 0;
  top: -3px;
  cursor: pointer;
}

.search_modal .search_box .search_button .search_btn {
  margin-right: 30px;
}

.search_modal .search_box .search_button .close {
  cursor: pointer;
  opacity: 1;
}

.search_modal .search_box input {
  border: 0 !important;
  border-bottom: 1px solid #ffffffb0 !important;
  border-radius: 0 !important;
  padding: 0;
  background-color: transparent !important;
  padding-right: 95px;
  color: #ffc900;
  font-family: "mulishitalic";
}

.search_modal .search_box input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.search_modal .search_box input::-webkit-input-placeholder {
  font-family: "mulishitalic";
}

.search_modal .search_box input::-moz-placeholder {
  font-family: "mulishitalic";
}

.search_modal .search_box input:-ms-input-placeholder {
  font-family: "mulishitalic";
}

.search_modal .search_box input::-ms-input-placeholder {
  font-family: "mulishitalic";
}

.search_modal .search_box input::placeholder {
  font-family: "mulishitalic";
}

.search_modal .search_btn {
  background-color: transparent;
  border: 0;
  color: white;
}

.search_modal .search_btn:focus,
.search_modal .search_btn:active {
  background-color: transparent !important;
  outline: 0;
  color: white;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.search_modal span {
  color: white;
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 0.3;
}

.desktop_search {
  font-size: 14px;
  font-family: "mulishregular" !important;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.5rem 1rem !important;
  display: block;
}

.header .logo_color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .logo_white {
  display: none;
}

header .logo_white {
  background: url("./logowhite.png") no-repeat;
  background-position: right bottom 0;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  vertical-align: top;
  /* display: flex; */
  padding: 0;
}

header.navTransparent {
  background-color: transparent;
}

/* .navTransparent .logo_white{
  display: flex !important;
} */
.navTransparent .logo_color {
  display: none;
}

.navTransparent .navbar-light .navbar-nav .nav-link {
  /* color: #d5d5d5; */
  color: #e3e3e3;
}

.navTransparent .nav-item.active .nav-link {
  color: white;
}

.navTransparent .nav-item .nav-links.desktop_search i {
  color: white;
}

/* Start Hover Dropdown Open CSS Code 12/10/2023*/
.dropdown:hover .dropdown-menu {
  display: block;
  /* margin-top: 0; */
}

/* End Hover Dropdown Open CSS Code 12/10/2023*/

/* End Header Portion Code CSS  */
