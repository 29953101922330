/* Browsers: last 4 version */

/* Start Testimonials Carousel Portion CSS Code Here  */
.testimonial_carousel {
  margin-bottom: 50px;
}
.testimonial_carousel h5 {
  font-family: "silomregular";
  color: #2c2c2e;
}
.testimonial_carousel .carousel img {
  width: 100%;
}
.testimonial_carousel .carousel .carousel-control-prev,
.testimonial_carousel .carousel .carousel-control-next {
  display: none;
}
.testimonial_carousel .testimonial_descp {
  position: relative;
  padding: 40px;
  max-width: 450px;
  width: 100%;
  margin-top: 46px;
}
.testimonial_carousel .testimonial_descp div p {
  color: #195089;
  font-family: "mulishregular";
  font-size: 14px;
  margin-bottom: 25px;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}
.testimonial_carousel .testimonial_descp h6 {
  color: #2c2c2e;
  font-family: "mulishsemibold";
  font-size: 14px;
  margin-bottom: 4px;
}
.testimonial_carousel .testimonial_descp span {
  color: #2c2c2e;
  font-size: 14px;
}

.testimonial_carousel .testimonial_descp div .startquote {
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
}
.testimonial_carousel .testimonial_descp div .endquote {
  height: 30px;
  width: 30px;
  display: block;
  position: absolute;
  bottom: 75px;
  right: 0;
}
.testimonial_carousel .testimonial_descp p .endquote img,
.testimonial_carousel .testimonial_descp p .endquote img {
  width: 100%;
}
.testimonial_carousel .owl-nav {
  display: none;
}
.testimonial_carousel .owl-theme .owl-dots {
  margin-top: 50px;
}
.testimonial_carousel .owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background-color: #313131 !important;
}
.testimonial_carousel .owl-theme .owl-dots .owl-dot span {
  background-color: #4a4a4a82 !important;
  width: 30px;
  height: 3px;
}

/* End Testimonials Carousel Portion CSS Code Here  */

/* Start Testimonials Carousel Responsive Media Query CSS Code Here  */

@media only screen and (max-width: 991px) {
  .testimonial_carousel .owl-carousel .container-fluid .row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .testimonial_carousel .testimonial_descp {
    max-width: 100%;
  }
  .testimonial_carousel h5 {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial_carousel h5 {
    margin-bottom: 50px;
  }
  .testimonial_carousel .owl-theme .owl-dots {
    margin-top: 20px;
  }
}
/* End Testimonials Carousel Responsive Media Query CSS Code Here  */
