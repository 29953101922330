/* Browsers: last 4 version */

/* Start Blog Box Portion CSS Code Here */
.blog_content .col-md-6 {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}
/* .blog_content .col-md-6:nth-child(1) {
  margin-bottom: 40px;
}
.blog_content .col-md-6:nth-child(2) {
  margin-bottom: 40px;
} */
.blog_content .blog_card {
  position: relative;
  border: 1px solid #70707063;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}
.blog_content .blog_card img {
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.blog_content .blog_card:hover .blog_overlay {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background: -o-radial-gradient(transparent, #00000091);
  background: radial-gradient(transparent, #00000091);
}
.blog_content .blog_card:hover img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.blog_content .blog_card .blog_overlay {
  padding: 30px;
  position: absolute;
  /* background-color: #2c2c2e70; */
  background: -o-radial-gradient(transparent, #000000bf);
  background: radial-gradient(transparent, #000000bf);
  height: 100%;
  width: 100%;
  color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.blog_content .blog_card .blog_layer {
  -webkit-transform: translate(-10px, -10px);
      -ms-transform: translate(-10px, -10px);
          transform: translate(-10px, -10px);
  overflow: hidden;
}
.blog_content .blog_card .blog_time {
  font-family: "mulishregular";
  font-size: 12px;
}
.blog_content .blog_card .blog_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
}
.blog_content .blog_card .blog_bottom h6 {
  /* font-family: "silomregular"; */
  font-family: 'mulishsemibold';
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  font-size: 13px;
  letter-spacing: 0.5px;
}
.blog_content .blog_card .blog_date {
  font-family: "mulishregular";
  font-size: 12px;
}

/* End Blog Box Portion CSS Code Here */