/* Browsers: last 4 version */

/* Start Insights Tab Content portion CSS Code here */
.insights_tab {
  margin-bottom: 100px;
}
.insights_tab .nav {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* height: 100%; */
  margin-top: 120px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.insights_tab .nav-pills .nav-link.active {
  background-color: transparent;
  color: #313131;
  font-family: "mulishbold";
  border-radius: 0;
  border: 1px solid #707070 !important;
}
.insights_tab .nav-pills .nav-link {
  padding: 4px 1rem;
  position: relative;
  border: transparent;
  background-color: transparent;
  color: #313131;
  font-family: "mulishregular";
  max-width: 150px;
  width: 100%;
  margin-bottom: 50px;
  font-size: 15px;
}
.insights_tab .nav-pills .nav-link.active::before {
  background-color: #313131 !important;
}
.insights_tab .nav-pills .nav-link::before {
  content: "";
  height: 2px;
  width: 20px;
  background-color: #3131316b;
  position: absolute;
  top: 14px;
  left: 17px;
}
/* End Insights Tab Content portion CSS Code here */
