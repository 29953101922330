/* Browsers: last 4 version */

/* Start Blog Detail Portion Responsive Media Query CSS Code Here  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog_detail .rotate_heading {
    left: -218px;
    bottom: 214px;
  }
}
@media only screen and (max-width: 991px) {
  .blog_detail .rotate_heading {
    position: unset;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}

@media only screen and (max-width: 767px) {
  .blog_detail .blog_carousel .owl-carousel .owl-nav {
    display: none;
  }
  .blog_detail .owl-carousel .blog_card .blog_bottom h6 {
    font-size: 12px;
  }
  .blog_detail .like_box {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .blog_detail .pr-0 {
    padding-right: 15px !important;
  }
  .blog_detail .pl-0 {
    padding-left: 15px !important;
  }
  .blog_detail .img_blog {
    margin-bottom: 0;
  }
}

/* End Blog Detail Portion Responsive Media Query CSS Code Here  */