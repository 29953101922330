/* Browsers: last 4 version */

/* Start Our Products Portion CSS Code Here */
.our_products {
  /* padding: 100px 0px; */
  padding: 90px 0px;
  background-color: #2c2c2e;
}
.our_products h2 {
  color: white;
  font-family: "silomregular";
}
.our_products p {
  color: white;
  margin: 50px 0px;
  font-family: "mulishregular";
}
.our_products .accordion .card .card-header {
  padding: 0;
}
.our_products .accordion .card .card-header .btn-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  padding: 1rem 0;
  width: 100%;
}
.our_products .accordion .card .card-header .btn-link #Path_215 {
  visibility: hidden;
}
.our_products .accordion .card .card-header .btn-link.collapsed #Path_215 {
  visibility: visible;
}
.our_products .accordion .pl-3 .card:last-child {
  border-bottom: transparent;
  padding-bottom: 0;
}
.our_products .accordion .pl-3 .card:last-child .card-header .btn-link {
  padding-bottom: 0px;
}
.our_products .accordion .card .card-header .btn-link .plus {
  margin-top: -5px;
  display: inline-block;
}
#outerAccordion .card .card-header h2 .btn-link span:first-child {
  text-transform: uppercase;
  display: inline-block;
}
.our_products .accordion .card {
  border-radius: 0;
  background-color: transparent;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid #ffffff63;
}

.our_products .accordion .card .card-header {
  padding-left: 0;
  padding-right: 0;
  border-color: transparent !important;
  border-radius: 0;
  background-color: transparent;
  border-top: transparent;
  border-left: transparent;
  border-right: transparent;
}
.our_products .accordion .card .product_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.our_products .accordion .card .card-body {
  padding-left: 0;
  padding-right: 0;
  padding-top: 3px;
  padding-bottom: 40px;
}
.our_products .accordion .card .product_card .p_img img {
  width: 100%;
}
.our_products .accordion .card .product_card .p_img {
  max-width: 180px;
}
/* .our_products .accordion .card .product_card .p_text {
  max-width: 344px;
  padding: 0 50px;
} */
.our_products .sub_head_a{
  margin-bottom: 20px;
}
.our_products .accordion .card .product_card .p_arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-left: 63px;
}
.our_products .accordion .card .product_card .p_text p {
  margin: 0;
  font-family: "mulishregular";
  /* font-family: "mulishsemibold"; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  max-width: 540px;
  width: 100%;
  color: #e7e7e7;
}
.our_products .p_btn {
  /* display: flex;
  align-items: flex-end;
  height: 100%;
  justify-content: center; */
  border: 1px solid #acacac;
  max-width: 175px;
  margin: auto;
}
.our_products .p_btn:hover .request_btn {
  background-color: #fdc02d !important;
  color: #2c2c2e !important;
}
.our_products .p_btn .request_btn:focus,
.our_products .p_btn .request_btn:active {
  color: #2c2c2e !important;
  background-color: #fdc02d !important;
}
.our_products .p_btn .request_btn {
  /* border: 1px solid #ffc014;
  color: #ffc014;
  border-radius: 0;
  padding: 20px 25px;
  font-size: 14px;
  font-family: "mulishregular";
  max-width: 176px;
  width: 100%; */
  border: 1px solid #ffc014;
  background-color: #2c2c2e !important;
  color: #fdc02d !important;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;

  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
      -ms-transform: translate(-5px, -5px);
          transform: translate(-5px, -5px);
  width: 173px;
  text-align: center;
}
.our_products .p_btn .request_btn:hover {
  font-family: "mulishsemibold";
}
.our_products .p_btn .request_btn:active {
  color: #ffc014;
  border-color: #ffc014;
}
/* End Our Products Portion CSS Code Here */