/* Browsers: last 4 version */

/* Start Services Page Portion Responsive Media Query CSS Code Here */

@media only screen and (min-width: 1500px) {
  .service_banner .banner_heading {
    max-width: 250px;
  }
}

@media only screen and (max-width: 991px) {
  .digit_box.digit_box_space {
    padding-right: 15px !important;
  }
  .digital_transform .digit_row .digit_box {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    margin-bottom: 15px;
  }
  .digital_transform .digit_row .digit_box:nth-child(2) {
    padding: 0 15px;
  }
  .digital_transform .digit_row {
    padding: 0px;
    padding-bottom: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service_banner .banner_desp {
    margin-bottom: 30px;
  }
  .digital_transform {
    margin: 70px 0px;
  }
  .digital_transform .digit_heading {
    padding-top: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .service_banner .banner_heading {
    margin: 0;
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .service_banner .service_header h2 {
    margin-bottom: 15px;
  }
  .service_banner .banner_desp {
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .digital_transform {
    margin: 50px 0px;
  }
  .digital_transform .digit_heading {
    padding-top: 50px;
  }
}

/* ENd Services Page Portion Responsive Media Query CSS Code Here */
