/* Browsers: last 4 version */

/* Start Nascent Latest Tab Content Portion Responsive Media Query CSS Code Here */
@media only screen and (min-width: 1500px) {
  .latest_nascent .latest_tabs {
    max-width: 745px;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .latest_nascent .latest_ntext {
    padding-right: 0;
  }

  .latest_nascent .nascent_slider {
    position: unset;
    margin: 20px 0px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .latest_nascent .latest_tabs {
    max-width: 503px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .latest_nascent .latest_tabs {
    max-width: 583px;
  }

  .latest_nascent {
    margin-bottom: 92px;
    padding-top: 70px;
  }

  .latest_nascent .nascent_slider svg {
    width: 15px;
  }

  .latest_nascent .see-all {
    margin-top: 10px;
  }

  .latest_nascent.nascent_pb {
    padding-bottom: 70px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest_nascent .latest_tabs {
    max-width: 404px;
  }

  .latest_nascent {
    margin-bottom: 50px;
  }

  .latest_nascent.nascent_pb {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .latest_nascent.nascent_pb {
    padding-bottom: 50px;
  }

  .latest_nascent .tab_box .download_button {
    /* margin-top: 15px; */
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    position: unset;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .latest_nascent .tab_box .download_button a:first-child {
    /* margin-right: 50px; */
    margin-bottom: 0;
  }

  .latest_nascent .latest_tabs .nav-tabs .nav-item {
    width: 96px;
    margin: auto;
  }

  .latest_nascent .nascent_slider {
    position: unset;
    margin: 20px 0px;
  }

  .latest_nascent .nascent_slider svg {
    /* width: 15px; */
    width: 10px;
  }

  .latest_nascent .latest_tabs .tab_content {
    margin-top: 15px;
  }

  .latest_nascent {
    padding: 50px 0;
    margin-bottom: 50px;
  }

  .latest_nascent .tab_box .social_content {
    margin-top: 15px;
  }
}

/* End Nascent Latest Tab Content Portion Responsive Media Query CSS Code Here */