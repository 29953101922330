/* Browsers: last 4 version */

/* Start Client Counter portion CSS Code Here  */
.counter h1 {
  text-align: center;
  color: #313131;
  font-family: "mulishbold";
}

.counter h6 {
  color: #313131;
  text-align: center;
  font-family: "mulishsemibold";
  font-size: 18px;
  margin-bottom: 40px;
}

.counter {
  /* margin-bottom: 115px; */
  /* margin-bottom: 90px; */
  /* margin-bottom: 130px; */
  margin-bottom: 150px;
}

/* End Client Counter portion CSS Code Here  */

/* Start Client Counter Portion Responsive Media Query CSS Code here  */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counter {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .counter {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .counter h6 {
    font-size: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .counter h6 {
    margin-bottom: 20px;
  }

  .counter h1 {
    margin-bottom: 50px;
    font-size: 30px;
  }
}

/* End Client Counter Portion Responsive Media Query CSS Code here  */
