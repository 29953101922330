.page_error h1 {
  /* color: #876744; */
  color: #313131;
  font-size: 90px;
  display: inline-block;
  font-family: "silomregular";
  margin: 0px;

  /* mix-blend-mode: luminosity; */
  /* opacity: 0.6; */
}
.page_error .nascent_logo {
  max-width: 150px;
}
.page_error .nascent_logo img {
  width: 100%;
}
.page_error h4 {
  text-transform: uppercase;
  font-family: "silomregular";
  /* font-family: "mulishbold"; */
  /* font-family: "caudexregular"; */
  margin: 0px;
  margin-bottom: 10px;
  margin-left: 25px;
  font-size: 20px;
  letter-spacing: 3px;
  color: #2c2c2e;
  /* color: #876744; */
  position: absolute;
  bottom: 3px;
  left: 160px;
}
.page_error:first-child {
  /* border-bottom: 2px dashed #a7a7a7; */
  border-bottom: 2px dashed #2c2c2e;
  max-width: 348px;
  width: 100%;
}
.page_error {
  position: relative;
}

.page_error svg {
  fill: #876744;
}
.page_error p {
  color: #313131;
  /* color: #876744; */
  font-family: "silomregular";
  /* font-family: "mulishsemibold"; */
  font-size: 13.5px;
  margin-top: 30px;
  letter-spacing: 2px;
}
.error_show {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-around;
}
.page_error a {
  text-decoration: none;
}

@media only screen and (max-width: 767px) {
  .page_error h1 {
    font-size: 50px;
  }
  .page_error h4 {
    font-size: 16px;
    bottom: -5px;
    left: 80px;
  }
}
