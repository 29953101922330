/* Browsers: last 4 version */

/* Start Data Loader Portion CSS Code Here */
.data_loader {
  top: 0;
  left: 0;
  background-color: #2c2c2e78;
  height: 100vh;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: fixed;
  z-index: 9999;
}
.data_loader div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: black;
  float: left;
  margin: 0 3px;
  background: #ffc014;
  /* background: #195089; */
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.data_loader .ball1 {
  z-index: 1;
  -webkit-animation: grow 4.4s infinite ease-in-out;
  animation: grow 4.4s infinite ease-in-out;
}
.data_loader .ball2 {
  -webkit-animation: grow 4.4s infinite ease-in-out;
  animation: grow 4.4s infinite ease-in-out;

  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.data_loader .ball3 {
  -webkit-animation: grow 4.4s infinite ease-in-out;
  animation: grow 4.4s infinite ease-in-out;

  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.data_loader .ball4 {
  -webkit-animation: grow 4.4s infinite ease-in-out;
  animation: grow 4.4s infinite ease-in-out;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background: #ffc014;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    background: #ffc014;
  }
}

@keyframes grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  75% {
    -webkit-transform: scale(1);
    transform: scale(1);
    background: #ffc014;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    background: #ffc014;
  }
}

/* End Data Loader Portion CSS Code Here */
