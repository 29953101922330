/* Browsers: last 4 version */

/* Start Service Transform Portion CSS Code Here */
.service_transform {
  margin-bottom: 5px;
}
.service_transform h2 {
  color: #2c2c2e;
  font-family: "silomregular";
  margin-bottom: 50px;
}
.service_transform .transform_box img {
  width: 100%;
}
.service_transform .transform_box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 1px solid #ffc014;
  cursor: pointer;
}
.service_transform .transform_box .overlay {
  position: absolute;
  background-color: #2c2c2e70;
  height: 95%;
  width: 95%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: white;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.service_transform .transform_box:hover .overlay {
  opacity: 1;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.service_transform .transform_box .overlay h6 {
  font-size: 15px;
  font-family: "mulishsemibold";
  margin: 0;
}
.service_transform .col-lg-4 {
  padding: 0;
}
/* End Service Transform Portion CSS Code Here */

/* Start Service Transform Portion Responsive Media Query CSS  */
@media only screen and (max-width: 991px) {
  .service_transform .transform_box .overlay h6 {
    font-size: 13px;
  }
  .service_transform .transform_box .overlay {
    opacity: 1;
  }
}
/* End Service Transform Portion Responsive Media Query CSS  */
