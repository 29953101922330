/* Browsers: last 4 version */

/* Start Swiper Content Portion CSS Code Here */
.tab-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}

.swiper_content {
  padding-left: 80px;
}

.swiper_content h5 {
  color: #2c2c2e;
  margin-bottom: 20px;
  font-family: "mulishbold";
  font-size: 16px;
}

.swiper_content p {
  color: #4a4a4a;
  /* -webkit-line-clamp: 10;
    -webkit-box-orient: vertical; */
  display: -webkit-box !important;
  overflow: hidden;
  margin-bottom: 30px;
  font-family: "mulishregular";
}

.swiper_content a {
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  font-family: "mulishregular";
  font-size: 14px;
}

.swiper_content a:hover {
  color: #007bff;
}

.swiper_content a svg {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.swiper_content a:hover svg {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* End Swiper Content Portion CSS Code Here */

/* Start Responsive Media Query CSS Code  */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .swiper_content {
    padding-left: 0;
  }
}

/* End Responsive Media Query CSS Code  */
