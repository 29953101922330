/* Browsers: last 4 version */

/* Start Services Page Portion CSS Code Here  */
.service_banner {
  margin-top: 120px;
  margin-bottom: 50px;
}

.service_banner .service_header h2 {
  font-family: "silomregular";
  margin-bottom: 0px;
  color: #313131;
}

.service_banner .service_header h3 {
  font-family: "silomregular";
  margin-bottom: 0px;
  color: #313131;
}

.service_banner .service_header p {
  color: #313131;
  font-family: "mulishlight";
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1px;
}

.service_banner .banner_heading {
  border: 1px solid #707070;
  text-align: center;
  max-width: 230px;
  padding: 10px 15px;
  margin: auto;
}

.service_banner .banner_heading h6 {
  margin: 0;
  color: #195089;
  font-family: "mulishsemibold";
}

.service_banner img {
  /* margin-top: 50px; */
  margin-top: 70px;
  width: 100%;
}

.service_banner .banner_desp {
  /* margin-bottom: 0;
  margin-top: 50px; */
  margin-bottom: 70px;
  margin-top: 90px;
}

/* End Services Page Portion CSS Code Here  */

/* Start Digital Transformation Portion CSS Code Here */
.digital_transform {
  /* margin: 80px 0px; */
  margin: 90px 0px;
}

.digital_transform .digit_row {
  background-color: #2c2c2e;
  padding: 50px;
  padding-top: 0;
  padding-bottom: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  color: white;
}

.digital_transform .digit_heading {
  background-color: #2c2c2e;
  padding-bottom: 70px;
  /* padding-top: 50px; */
  padding-top: 70px;
  position: relative;
}

.digital_transform .digit_heading::after {
  content: "";
  height: 25px;
  width: 1px;
  background-color: white;
  display: block;
  position: absolute;
  bottom: 38px;
  left: 50%;
  -webkit-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.digital_transform h5 {
  text-align: center;
  font-family: "silomregular";
  margin-bottom: 0;
  color: white;
}

.digital_transform p {
  color: white;
  font-family: "mulishregular";
  /* -webkit-line-clamp: 8; */
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
}

.digital_transform h6 {
  font-family: "mulishregular";
  margin-bottom: 50px;
  font-size: 15px;
}

.digital_transform .digit_row .digit_box {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
}

.digital_transform .digit_row .digit_box:nth-child(2) {
  padding: 0;
}

.digital_transform .digit_row .digit_box .digit_inner {
  border: 1px solid white;
  padding: 30px;
}
.digit_box.digit_box_space {
  padding-right: 0 !important;
}

/* End Digital Transformation Portion CSS Code Here */
