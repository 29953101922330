/* Browsers: last 4 version */

/* Start Get In Touch Portion CSS Code Here */
.getintouch .nascent_info {
  background-color: #f7f7f7;
  height: 100%;
  width: 100%;
  padding: 90px;
}

.getintouch {
  margin-bottom: 5px;
}

.getintouch .nascent_info .nascent_logo {
  margin-bottom: 15px;
  max-width: 135px;
}

.getintouch .nascent_info .nascent_logo img {
  width: 100%;
}

.getintouch .nascent_info p,
.getintouch .nascent_info a {
  font-family: "mulishregular";
  color: #313131;
  text-decoration: none;
}

.getintouch .nascent_info a:hover {
  color: #0a5187;
}

.getintouch .col-lg-8 {
  padding-left: 0;
  padding-right: 0;
}

.getintouch .col-lg-4 {
  padding-right: 0;
  padding-left: 5px;
}

.getintouch .nascent_info p:last-child {
  margin-bottom: 0;
}

.getintouch h2 {
  color: #313131;
  font-family: "silomregular";
  text-align: center;
  margin-bottom: 50px;
}

.getintouch iframe {
  border-style: none;
}

/* End Get In Touch Portion CSS Code Here */

/* Start Get In Touch Responsive Media Query CSS Code Here  */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .getintouch .nascent_info {
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .getintouch .nascent_info {
    padding: 40px;
  }
}

@media only screen and (min-width: 575px) and (max-width: 767px) {
  .getintouch .nascent_info {
    padding: 40px;
  }
}

@media only screen and (max-width: 574px) {
  .getintouch .col-lg-4 {
    padding-left: 0;
  }

  .getintouch .nascent_info {
    padding: 15px;
  }
}

/* End Get In Touch Responsive Media Query CSS Code Here  */
