/* Browsers: last 4 version */

/* Start Papers Detail Portion Responsive Media Query CSS Code Here */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .papers_detail .rotate_heading {
    left: -218px;
    bottom: 214px;
  }
}
@media only screen and (max-width: 991px) {
 
  .papers_detail .rotate_heading{
    margin-bottom: 40px !important;
    margin-left: 5px;
    position: unset;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
    text-align: center;
    margin: auto;  
}
  .papers_detail .papers_card_box .paper_text{
    padding: 15px;
  }
  .papers_detail .papers_card_box .papers_descp{
    padding: 15px;
  }
  .papers_detail .papers_card_box{
    margin-top: 20px;
  }
}

/* End Papers Detail Portion Responsive Media Query CSS Code Here */