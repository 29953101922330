/* Browsers: last 4 version */

/* Start Home Page Carousel We Believe Collaborations create scope of technical interventions Portion CSS Code Here   */
.home_carousel {
  padding-bottom: 100px;
  /* padding-bottom: 90px; */
}
.home_carousel .carousel_title {
  color: #313131;
  font-family: "silomregular";
}
.home_carousel h5 {
  margin-bottom: 0;
}
.home_carousel h2 {
  color: #313131;
  font-family: "silomregular";
  /* margin-bottom: 70px; */
  margin-bottom: 90px;
}
.home_carousel .card {
  border: 0;
  width: 18rem;
  margin: auto;
}
.home_carousel .card .card-img-top {
  max-width: 180px;
  margin: auto;
}
.home_carousel .owl-carousel .card .card-title {
  font-family: "mulishbold";
  text-align: center;
}
.home_carousel .owl-carousel .card .card-text {
  font-family: "mulishregular";
  color: #4a4a4a;
}
.home_carousel .owl-carousel .owl-nav.disabled {
  display: block;
}

.home_carousel .owl-nav {
  position: absolute !important;
  top: 45%;
  width: 100%;
}
.home_carousel .owl-dots {
  display: none;
}
.owl-prev {
  left: 0;
  position: absolute;
  font-size: 40px !important;
  outline: none !important;
  background-color: transparent !important;
}

.owl-next {
  right: 0;
  position: absolute;
  font-size: 40px !important;
  outline: none !important;
  background-color: transparent !important;
}
.home_carousel .owl-nav .owl-next::after ,.home_carousel .owl-nav .owl-prev::after {
  content: "";
  height: 40px;
  width: 40px;
  background-color: #f5f5f5;
  border-radius: 100%;
  display: block;
  position: absolute;
  top: 16px;
  left: -14px;
  z-index: -1;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(0.5);
      -ms-transform: scale(0.5);
          transform: scale(0.5);
}
.home_carousel .owl-nav .owl-next:hover:after,.home_carousel .owl-nav .owl-prev:hover:after {
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  opacity: 1;
}
.home_carousel .owl-nav {
  position: relative;
  z-index: 2;
}
.owl-prev:hover span {
  color: #313131;
  background-color: transparent !important;
}
.owl-next:hover span {
  color: #313131;
  background-color: transparent !important;
}

/* End Home Page Carousel We Believe Collaborations create scope of technical interventions Portion CSS Code Here   */