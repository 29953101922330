/* Browsers: last 4 version */

/* Start Our Culture Portion CSS Code Here */
.our_culture {
  margin: 115px 0px;
  /* margin: 90px 0px; */
}
.our_culture h2 {
  color: #313131;
  font-family: "silomregular";
  /* margin-top: 50px; */
  margin-top: 30px;
  margin-bottom: 50px;
}
.our_culture p {
  font-family: "mulishregular";
  color: #313131;
}
.our_culture .join_us_button {
  border: 1px solid #313131;
  max-width: 147px;
  margin-top: 50px;
}
.our_culture .join_us_btn {
  /* border: 1px solid #ffc014;
  color: #313131;
  border-radius: 0px;
  padding: 10px 35px;
  font-size: 14px;
  font-family: "noto_sansregular";
  transition: all 0.5s ease-in-out;
  margin-top: 30px; */
  color: #2c2c2e;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  background-color: white !important;
  border: 1px solid #ffc014;
  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
  -ms-transform: translate(-5px, -5px);
  transform: translate(-5px, -5px);
  width: 145px;
}
.our_culture .join_us_btn:focus,
.our_culture .join_us_btn:active {
  color: #2c2c2e !important;
  border-color: #ffc014 !important;
}
.our_culture .join_us_button .join_us_btn:hover {
  background-color: #fdc02d !important;
}
/* .our_culture .join_us_btn:hover {
  transform: scale(1.1);
  transition: all 0.5s ease-in-out;
  background-color: #313131 !important;
  border-color: transparent;
  color: #ffc014;
  font-size: 14px;
} */
.our_culture img {
  width: 100%;
}
.our_culture .img_box {
  max-width: 250px;
  /* margin: auto; */
  margin-left: auto;
}
.our_culture .culture_text {
  padding-right: 40px;
}
/* End Our Culture Portion CSS Code Here */

/* Start Our Culture Portion Responsive Media Query CSS Code Here  */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our_culture h2 {
    margin-top: 0;
  }
  .our_culture .culture_text {
    padding-right: 0px;
  }
  .our_culture .culture_img {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 50px;
  }
  .our_culture .img_box {
    max-width: 33.33%;
  }
  .our_culture {
    margin: 70px 0px;
  }
}
@media only screen and (max-width: 767px) {
  .culture_img {
    margin-top: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .our_culture .img_box {
    max-width: 100%;
    margin: 0;
  }
  .our_culture {
    margin-bottom: 70px;
    margin-top: 0px;
  }
  .our_culture .join_us_btn {
    padding: 8px 20px;
    font-size: 12px;
  }
  .our_culture .culture_text {
    padding-right: 0;
  }
  .our_culture h2 {
    margin-top: 50px;
  }
}
/* End Our Culture Portion Responsive Media Query CSS Code Here  */
