/* Browsers: last 4 version */

/* Start Tab Swiper Portion CSS Code Here */

.tabswiper {
  margin-bottom: 50px;
}

.tabswiper .tabswiper_heading {
  font-family: "silomregular";
  /* margin-bottom: 50px; */
  margin-bottom: 70px;
}

.tabswiper .nav-tabs {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  float: left;
  border-bottom: 0;
  position: relative;
  margin-top: 13px;
  padding-left: 40px;
}

.tabswiper .nav-tabs:before {
  content: "";
  width: 1px;
  /* height: 84%; */
  /* height: 80%; */
  height: 70%;
  background-color: lightgrey;
  position: absolute;
  /* left: -24px; */
  left: 16px;
  top: 19px;
}

.tabswiper .nav-tabs .nav-item {
  /* width: 130px; */
  width: 150px;
  margin-bottom: 60px;
  padding-top: 10px;
}

.tabswiper .nav-tabs .nav-item .nav-link {
  text-align: left;
  border-color: transparent;
  font-family: "mulishmedium";
  /* font-family: "silomregular"; */
  background-color: transparent;
  color: #4a4a4a !important;
}

.tabswiper .nav-tabs .nav-item .nav-link p {
  font-size: 12px;
  margin: 0;
  color: #4a4a4a !important;
}

.tabswiper .nav-tabs .nav-item .circle_no {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 30px;
  width: 30px;
  background-color: #9a9a9a;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  color: white;
  position: absolute;
  top: 18px;
  left: -38px;
  font-size: 12px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tabswiper .nav-tabs .nav-item {
  position: relative;
}

.nav-tabs .nav-link.active {
  color: #2c2c2e;
  font-family: "mulishbold" !important;
}

.tabswiper .nav-tabs .nav-link.active .circle_no {
  background-color: #ffc014 !important;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.tabswiper .nav-tabs .nav-link.active {
  color: #313131 !important;
}

.swiper-3d .swiper-slide img {
  width: 100%;
}

.swiper-slide-prev {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}

/* .owl-carousel .owl-item.active.center .item {
  transform: scale(1.5) !important;
} */

.bring_swiper {
  max-width: 380px;
  /* margin-top: 41px; */
}

/* End Tab Swiper Portion CSS Code Here */
