/* Browsers: last 4 version */

/* Start Video Portion CSS Code Here   */
#nascent_home .video_banner {
  margin-bottom: 45px;
}
#nascent_home .video_banner .overlay {
  background-color: #0000003d;
}
/* .video_banner .overlay{
  opacity: 0.2;
} */
/* End Video Portion CSS Code Here   */

/* Start Video Portion Responsive Media Query Code Here  */
@media only screen and (max-width: 991px) {
  #nascent_home .video_banner {
    margin-bottom: 32px;
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  #nascent_home .video_banner {
    margin-top: 0;
  }
}
/* End Video Portion Responsive Media Query Code Here  */
