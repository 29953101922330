/* Browsers: last 4 version */

/* Start Header Portion Code Responsive Media Query CSS  */
@media only screen and (min-width: 992px) {
  .mobile_search {
    display: none;
  }
  body.modal-open header.visible {
    padding-right: 17px !important;
  }
  .navTransparent .logo_white {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
}
@media only screen and (max-width: 991px) {
  header .navbar .navbar-collapse {
    /* text-align: center; */
    padding-top: 20px;
  }
  header .navbar-nav .nav-link {
    font-size: 16px;
  }
  header.visible {
    background-color: white;
  }
  .logo_white {
    display: none !important;
  }
  .logo_color {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  header {
    padding: 15px 0px;
  }
  .desktop_search {
    display: none;
  }
  .mobile_search {
    display: block;
    margin-left: auto;
    color: #2c2c2e;
    font-size: 15px;
    padding-left: 0px;
    padding-right: 0;
    padding-top: 9px;
  }
  .mobile_search i {
    /* color: #ffc900; */
    color: #313131;
    font-size: 18px;
    margin-top: 2px;
  }
  header .navbar-light .navbar-toggler:focus {
    outline: 0;
  }
  header .navbar-light .navbar-toggler {
    border: 0;
    padding: 0;
    width: 42px;
  }
  header .navbar-nav .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .search_modal .modal-content {
    height: 69px;
  }
  .search_modal .search_box {
    margin-top: 0;
  }

  .navbar-nav {
    height: 50vh;
    overflow: hidden;
    margin-top: 30px;
  }
  .scrolling-hidden {
    overflow: hidden;
    position: relative;
  }
  .scrolling-hidden::before {
    content: "";
    /* position: absolute; */
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    background-color: #2c2c2e;
    opacity: 0.6;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
  header .navbar .ham {
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-transition: -webkit-transform 400ms;
    transition: -webkit-transform 400ms;
    -o-transition: transform 400ms;
    transition: transform 400ms;
    transition: transform 400ms, -webkit-transform 400ms;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: 50px;
    margin-right: -19px;
    width: 47px;
  }
  header .navbar .hamRotate.active {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  header .navbar .hamRotate180.active {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  header .navbar .line {
    fill: none;
    -webkit-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    -o-transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
    stroke: #313131;
    stroke-width: 5px;
    stroke-linecap: round;
  }
  header .navbar .ham1 .top {
    stroke-dasharray: 40 139;
  }
  header .navbar .ham1 .bottom {
    stroke-dasharray: 40 180;
  }
  header .navbar .ham1.active .top {
    stroke-dashoffset: -98px;
  }
  header .navbar .ham1.active .bottom {
    stroke-dashoffset: -138px;
  }
  video {
    height: auto !important;
    width: 100%;
  }
  .video_banner {
    margin-top: 79px;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #313131 !important;
  }
  header .navbar-nav .dropdown .dropdown-menu {
    -webkit-box-shadow: none;
    box-shadow: none;

    /* box-shadow: inset 0 0 5px #00000029, 0px 0px 0px 0 #00000029; */
  }
}

/* End Header Portion Code Responsive Media Query CSS  */
