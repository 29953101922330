/* Browsers: last 4 version */

/* Start Papers Content Portion CSS Code Here  */
.papers_content .papers_card {
  position: relative;
  border: 1px solid #70707063;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}

.papers_content .col-md-6 {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 40px;
}

.papers_content .papers_card .papers_text h6 {
  color: #2c2c2e;
  font-family: "mulishbold";
  letter-spacing: 0.5px;
  /* font-family: "silomregular"; */
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  margin-bottom: 16px;
  font-size: 13px;
}

.papers_content .papers_card .papers_text {
  padding: 30px;
}

.papers_content .papers_card .papers_text p {
  color: #313131;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  font-family: "mulishregular";
  margin: 0;
}

.papers_content .papers_card .papers_img img {
  width: 100%;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.papers_content .papers_card .papers_overlay {
  border: 1px solid #707070;
  -webkit-transform: translate(-10px, -10px);
      -ms-transform: translate(-10px, -10px);
          transform: translate(-10px, -10px);
  background-color: white;
}

.papers_content .papers_card .papers_img {
  overflow: hidden;
}

.papers_content .papers_card:hover .papers_img img {
  -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

/* End Papers Content Portion CSS Code Here  */




/* Start Papers Content Responsive Media Query CSS Portion Code Here  */
@media only screen and (max-width: 767px) {
  .papers_content .papers_card .papers_text {
    padding: 20px;
  }
}

/* End Papers Content Responsive Media Query CSS Portion Code Here  */