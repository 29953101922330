/* Browsers: last 4 version */


/* Start Home Carousel Responsive Media Query CSS Code  */
@media only screen and (min-width: 768px) and (max-width: 991px){
    .home_carousel h2{
        margin-bottom: 50px;
    }
    .case_studies .c_title{
        margin-bottom: 50px;
    }
    .home_carousel .owl-dots{
        display: block;
    }
    .home_carousel .owl-carousel .owl-nav.disabled{
        display: none;
    }
}
@media only screen and (max-width: 767px){
    .home_carousel h2{
        margin-bottom: 23px;
    }
    .home_carousel .owl-nav{
        display: none;
    }
    .home_carousel .owl-dots{
        display: block;
    }
    .home_carousel .owl-carousel .owl-nav.disabled{
        display: none;
    }
}
/* End Home Carousel Responsive Media Query CSS Code  */