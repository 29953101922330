/* Browsers: last 4 version */

/* Start Case Studies Home Page Portion Media Query Responsive CSS Code Here  */
@media only screen and (min-width: 992px) {
  .case_studies_top .case_top h5 {
    max-width: 380px;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .case_studies_home .case_tab ul {
    display: inherit;
  }
  .case_studies_home .case_tab ul li {
    width: 50%;
    display: block;
    float: left;
    margin: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  .case_studies_home .case_tab ul li:after{
    right: 0;
  }

  .case_studies_home .case_tab {
    margin-bottom: 150px;
  }
  .case_studies_home .case_tab ul li:nth-child(2n)::after {
    display: none;
  }
  .case_studies_home .case_tab ul li:last-child::after {
    display: block;
  }
}
@media only screen and (max-width: 767px) {

  .case_studies_home .case_tab ul li:after{
    right: 0;
  }
  .case_studies_home .card {
    padding: 0;
    margin-bottom: 30px;
  }
  .case_studies_home .case_tab ul li:nth-child(2n)::after {
    display: none;
  }
  .case_studies_home .case_tab ul li:last-child::after {
    display: block;
  }
  .case_studies_home .input-group{
    margin-bottom: 30px;
  }
  .case_studies_home .card .card-body .card_tag{
    font-size: 14px;
  }
}
/* End Case Studies Home Page Portion Media Query Responsive CSS Code Here  */