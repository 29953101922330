/* Browsers: last 4 version */

/* Start Service Slider Portion CSS Code Here */

.service_slider {
  background-color: #2c2c2e;
  padding: 90px 0px;
  margin-bottom: 90px;
}

.service_slider p {
  color: white;
  font-family: "noto_sansregular";
  letter-spacing: 0.5px;
}

.service_slider a svg {
  height: 12px;
  width: 12px;
}

.service_slider a {
  color: white;
  text-decoration: none;
  font-size: 13px;
}

.service_slider .owl-carousel .item .slider_card .s_card p {
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  font-family: "mulishregular";
}

.service_slider a.view_case {
  color: #ffc014;
  margin-left: 70px;
}

.service_slider .owl-carousel .item .slider_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.service_slider .owl-carousel .item .slider_card .s_card {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 1px;
}

.service_slider .owl-carousel .item .slider_card img {
  width: 100%;
}

.service_slider .owl-carousel .item .slider_card .s_card h5 {
  color: white;
  font-family: "silomregular";
  margin-bottom: 40px;
}

.service_slider .owl-carousel .item .slider_card .s_card:first-child {
  padding-left: 70px;
  padding-right: 60px;
}

.service_slider .owl-carousel .item .slider_card .service_img {
  max-width: 370px;
  width: 100%;
  margin-left: auto;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 2s infinite alternate;
}

.service_slider .owl-carousel .item .slider_card .s_card .slide_counter {
  opacity: 0.3;
  margin-bottom: 30px;
}

.service_slider .owl-carousel .owl-dots.disabled {
  display: block;
}

.service_slider .owl-theme .owl-dots .owl-dot.active span,
.service_slider .owl-theme .owl-dots .owl-dot:hover span {
  background-color: white !important;
  opacity: 0.3;
}

.service_slider .owl-theme .owl-dots .owl-dot span {
  background-color: white;
  opacity: 1 !important;
  height: 50px !important;
  width: 3px !important;
}

.service_slider .owl-theme .owl-dots {
  position: absolute;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 50px;
}

.service_slider .owl-theme .owl-nav {
  position: absolute;
  right: 0;
  /* bottom: -42px; */
}

.service_slider .owl-theme .owl-nav span {
  color: white;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.service_slider .owl-carousel .owl-next {
  right: auto !important;
  position: unset !important;
}

.service_slider .owl-carousel .owl-prev {
  left: auto !important;
  position: unset !important;
  margin-right: 30px;
}

.service_slider .owl-carousel .owl-item .slider_card h5,
.service_slider .owl-carousel .owl-item .slider_card p {
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 0;
}

.service_slider .owl-carousel .owl-item.active .slider_card h5,
.service_slider .owl-carousel .owl-item.active .slider_card p {
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 1;
}

/* End Service Slider Portion CSS Code Here */

/* Start Service Slider Portion Responsive Media Query CSS Code Here  */

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-dots {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .service_slider .owl-carousel .item .slider_card .service_img {
    max-width: 280px;
  }
  .service_slider .owl-carousel .item .slider_card .s_card:first-child {
    padding-left: 50px;
    padding-right: 0px;
  }
  .service_slider a.view_case {
    margin-left: 50px;
  }
  .service_slider .owl-carousel .owl-nav {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    bottom: -90px;
  }
}

@media only screen and (max-width: 767px) {
  .service_slider .owl-carousel .item .slider_card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .service_slider .owl-theme .owl-dots {
    bottom: 0;
    width: 100%;
    top: 100%;
  }
  .service_slider .owl-theme .owl-dots .owl-dot span {
    height: 3px !important;
    width: 30px !important;
  }
  .service_slider .owl-carousel .item .slider_card .s_card {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 1px;
  }
  .service_slider .owl-carousel .item .slider_card .s_card:first-child {
    padding: 0px;
    margin-bottom: 50px;
  }
  .service_slider a.view_case {
    margin: 0;
  }
  .service_slider {
    padding: 50px 0px;
  }
  .service_slider .owl-carousel .owl-nav {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

/* End Service Slider Portion Responsive Media Query CSS Code Here  */
