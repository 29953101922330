/* Browsers: last 4 version */

/* Start Join Us Portion CSS Code Here */
.joinus_box {
  margin-top: 120px;
  /* margin-bottom: 50px; */
  margin-bottom: 90px;
}

.joinus_box .join_heading_top {
  color: #313131;
  font-family: "mulishlight";
  margin-bottom: 10px;
  font-size: 13px;
  letter-spacing: 1px;
}

.joinus_box .join_us_heading {
  /* text-align: center; */
  margin:50px 0; 
}

.joinus_box .join_us_heading p{
  font-family: "mulishregular";
}
.joinus_box .join_us_heading h5 {
  font-family: "silomregular";
  color: #2c2c2e;
}

.joinus_box .join_us_heading h2 {
  font-family: "silomregular";
  color: #2c2c2e;
}

.joinus_box .join_first_img img {
  width: 100%;
}

.joinus_box .join_second_img img {
  width: 100%;
}

.joinus_box .join_first_img {
  padding-left: 30px;
}

.joinus_box .join_second_img {
  padding-left: 30px;
  margin-top: 30px;
}

.joinus_box .join_us_img {
  max-width: 283px;
  width: 100%;
}

.joinus_box .join_banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.joinus_box .join_us_img img {
  width: 100%;
}

.joinus_box .join_us_para {
  /* margin-top: 30px !important; */
  max-width: 420px;
  /* margin: auto; */
  margin-left: auto;
  font-family: 'silomregular';
}
.joinus_box .join_us_para h6 {
  margin-left: -45px;
  margin-bottom: 40px;
}

/* .joinus_box .join_us_para p {
  font-family: "mulishregular";
  color: #2c2c2e;
  margin-bottom: 40px;
}
.joinus_box .join_us_para p span {
  margin-right: 30px;
  font-size: 16px;
} */
.joinus_box .join_us_para ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
}

.joinus_box .join_us_para ul {
  list-style-type: none;
  position: relative;
  counter-reset: css-counter 0;
  /* initializes counter to 0; use -1 for zero-based numbering */
}

.joinus_box .join_us_para ul li {
  counter-increment: css-counter 1;
  /* Increase the counter by 1. */
  margin-bottom: 40px;
  font-family: "mulishregular";
  color: #2c2c2e;
  font-size: 14px;
}

.joinus_box .join_us_para ul li:before {
  content: counter(css-counter) ".";
  /* Apply counter before children's content. */
  position: absolute;
  left: -45px;
  font-size: 16px;
}

.joinus_box .join_us_para .position_btn {
  border: 1px solid #313131;
  max-width: 175px;
  /* margin: auto; */
  margin-top: 55px;
}

.joinus_box .position_btn button:hover,
.joinus_box .position_btn button:focus {
  background-color: #fdc02d !important;
  border-color: #fdc02d !important;
}

.joinus_box .position_btn button {
  color: #2c2c2e;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;

  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
      -ms-transform: translate(-5px, -5px);
          transform: translate(-5px, -5px);
  width: 173px;
  text-align: left;
  border: 1px solid #fdc02d;
  background-color: white !important;
}

.joinus_box .join_us_para .position_btn button:hover,
.joinus_box .join_us_para .position_btn button:active,
.joinus_box .join_us_para .position_btn button:focus {
  color: #313131;
}

.joinus_box .join_us_para .position_btn button svg {
  height: 12px;
  width: 12px;
  fill: #2c2c2e;
  margin-left: 10px;
}

.job_portion {
  padding-top: 50px;
  /* margin-top: 160px; */
  margin-top: 90px;
}

.joinus_box .job_box {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  position: relative;
}

.joinus_box .job_box .job_list {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 80%;
          flex: 0 0 80%;
  max-width: 80%;
  padding: 30px 100px;
  background-color: #2c2c2eed;
  border-bottom: 1px solid #2c2c2eed;
}

.joinus_box .job_box .job_button {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%;
  background-color: #2c2c2e;
}

.joinus_box .job_box .job_button:hover::after {
  -webkit-transform: translateX(0%);
      -ms-transform: translateX(0%);
          transform: translateX(0%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.joinus_box .job_box .job_button:after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #676767cf;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(80%);
      -ms-transform: translateX(80%);
          transform: translateX(80%);
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.joinus_box .job_box .job_list h6 {
  font-family: "mulishsemibold";
}

.joinus_box .job_box .job_list p {
  font-family: "mulishregular";
  color: white;
  opacity: 0.6;
  margin: 0;
  display: inline-block;
}

.joinus_box .job_box .job_list .dot {
  height: 5px;
  width: 5px;
  border-radius: 100%;
  background-color: #ffffff;
  margin: -15px 5px 0px 15px;
  display: inline-block;
}

.joinus_box .job_box .job_list a {
  font-family: "mulishregular";
  color: white;
  opacity: 0.6;
  margin: 0;
  line-height: 21px;
  font-size: 14px;
  margin-left: 10px;
  text-decoration: none;
}

.joinus_box .job_box button {
  color: #ffc014;
  text-transform: uppercase;
  font-size: 12px;
  height: 100%;
  width: 100%;
  font-family: "mulishsemibold";
  border-radius: 0;
}

.upload_box {
  background-color: #ebebeb;
  padding: 70px 0px;
  /* margin-bottom: 50px; */
  margin-bottom: 5px;
}
.upload_box p{
  margin-bottom: 60px;
  position: relative;
  font-family: "mulishregular";
  font-size: 17px;
}
.upload_box p::after{
  content: "";
  width: 1px;
  height: 30px;
  background-color: #707070;
  position: absolute;
  left: 0;
  bottom: -46px;
}

.upload_box h5 {
  margin-bottom: 20px;
}

.upload_box h5  a {
  color: #2c2c2e;
  /* text-decoration: none; */
}

.upload_box .position_btn button:hover {
  background-color: #fdc02d !important;
  padding-left: 18px;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.upload_box .position_btn button {
  color: #2c2c2e;
  text-transform: uppercase;
  font-family: "mulishregular";
  font-size: 12px;
  letter-spacing: 1px;
  background-color: #fdc02d !important;
  /* background-color: #ebebeb !important; */
  border: 1px solid #fdc02d;
  border-radius: 0;
  -webkit-transform: translate(-5px, -5px);
      -ms-transform: translate(-5px, -5px);
          transform: translate(-5px, -5px);
  width: 173px;
  text-align: left;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.upload_box .position_btn button:focus,
.upload_box .position_btn button:hover,
.upload_box .position_btn button:active {
  border-color: #fdc02d !important;
}

.upload_box h5 {
  color: #2c2c2eeb;
  font-family: "mulishsemibold";
  margin-bottom: 70px;
}
.upload_box h5 a {
  font-family: "mulishbold";
  display: inline-block;
}
.upload_box h5 .simple{
  font-family: "mulishbold";
  font-size: inherit;
  display: inline-block;
}
.upload_box .position_btn {
  border: 1px solid #313131;
  max-width: 175px;
  margin: auto;
  /* margin-left: auto; */
}

.upload_box .position_btn button svg {
  height: 12px;
  width: 12px;
  fill: #2c2c2e;
  margin-left: 10px;
  -webkit-transform: rotate(-180deg);
      -ms-transform: rotate(-180deg);
          transform: rotate(-180deg);
  margin-top: -3px;
}

.upload_box .position_btn button:hover,
.upload_box .position_btn button:active,
.upload_box .position_btn button:focus {
  color: #313131 !important;
}

.upload_box span {
  font-family: "mulishregular";
  font-size: 16px;
  display: inline-block;
}

.upload_box span a {
  font-family: "mulishregular";
  color: #195089 !important;
  text-decoration: none;
}

.file-upload .file-select {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.file-upload .file-select input {
  position: absolute;
  top: 0;
  opacity: 0;
  
}

.file-upload .file-select .file-select-name {
  position: absolute;
  top: 5px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  width: 155px;
}

.file-upload .fa-upload {
  position: absolute;
  right: 15px;
  color: #5A5A5A;
}

.file-upload .file-select .file-select-button {
  opacity: 0;
}

.file-upload .material-symbols-outlined {
  font-size: 20px;
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 7px;
}

/* End Join Us Portion CSS Code Here */






/*Start File Upload Input Portion Code CSS*/
.collaborate_form .file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  border: 1px solid #876744;
  border-radius: 0;
  z-index: 1;
}


.collaborate_form .file-upload {
  position: relative;
}

.collaborate_form .fake-btn {
   font-size: 12px;
  
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box !important;
  overflow: hidden;
  width: 220px;
  

}
.collaborate_form .mobile_fake_msg{
  max-width: 150px;
  width: 100%;
}







/*End File Upload Input Portion Code CSS*/

.collaborate_form form label {
  color: #876744;
}

.collaborate_form .validation {
  margin-bottom: 35px;
  
}


/* Start Join Us Responsive Media Query CSS Code Here  */
@media only screen and (max-width: 767px) {
  .upload_box .position_btn {
    margin: auto;
    margin-top: 50px !important;
  }
  .collaborate_form .mobile_fake_msg{
    max-width: 85%;
    width: 100%;
  }
  
}

/* End Join Us Responsive Media Query CSS Code Here  */