/* Browsers: last 4 version */

/* Start Our Products Portion Responsive Media Query CSS Code Here */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .our_products .accordion .card .product_card .p_text p{
    -webkit-line-clamp: 3;
  }
  .our_products{
    padding: 70px 0px;
  }
  /* .our_products .p_btn .request_btn {
    border: 1px solid #ffc014;
    color: #ffc014;
    border-radius: 0;
    padding: 19px 11px;
    font-size: 14px;
    font-family: "mulishregular";
    max-width: 108px;
    width: 100%;
  } */
}
@media only screen and (max-width: 767px) {
  .our_products .accordion .card .product_card .p_arrow{
    margin-left: 0;
  }
  .our_products {
    padding: 50px 0;
  }
  .our_products .accordion .card .product_card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .our_products .accordion .card .product_card .p_img {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .our_products .accordion .card .product_card .p_text {
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: 15px;
  }
  .our_products .p_btn {
    margin-top: 50px;
    /* height: auto; */
  }
  /* .our_products .p_btn .request_btn {
    padding: 20px 15px;
    max-width: 160px;
  } */
}
/* End Our Products Portion Responsive Media Query CSS Code Here */